import axios from "axios";  
import { baseBackendURL } from "../constants";
import { getToken } from "./recruiter";

export async function uploadEmail(email, customerID) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/uploadEmail?email=${email}&customerID=${customerID}`, {}, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}

export async function reportRecruiter(reportType,reportObj) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/reportRecruiter?type=${reportType}`, reportObj, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}