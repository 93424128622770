import React, { useEffect } from "react";
import logo from "../assets/long_logo.png";
import AuthPopup from "../Auth/Auth_popup";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { LinkedInApi,NodeServer } from "../constants";
import smallerLogo from "../assets/mini_logo.png";
import long_logo from "../assets/long_logo_singleline.png";
import profile_placeholder from '../assets/profile_placeholder.png';
import { plausible } from "../App";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

export const navigateToCorrectPage = (user) => {
        if(user.loggedIn && !user.email_verified){
            return window.location.href = "/email_verification";
        }
        if(user.recruiter){
            if(user.recruiter.name){ 
                return window.location.href = "/recruiter_dashboard";
            }
            return window.location.href = "/onboarding";
        }
        if(user.customer){
            return window.location.href = "/select_service";
        }
        return window.location.href = "/";
    }
function Header(props) {

    const user = useSelector((state) => state.user);

    const [anchorEl, setAnchorEl] = useState(null); // State for the anchor element of the menu
    const dispatch = useDispatch();

    const [openAuth, setOpenAuth] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isLandingPage, setIsLandingPage] = useState(false);

    useEffect(() => {
        if(window.location.pathname === "/"){
            setIsLandingPage(true);
        }else{
            setIsLandingPage(false);
        }
    },[]);

    const userSetSelected = (selected) => {
        plausible.trackEvent('Header - Menu Item Click', {props: {selected:selected}});
        navigateToElement(selected);
    }

    const openAuthPopup = () => {
        let selected_role = null;
        if(window.location.pathname === "/recruiter_welcome"){
            selected_role ="recruiter"
        }else{
            selected_role="customer" 
        }
        plausible.trackEvent('Header - Login Button', {props: {role: selected_role}});
        setOpenAuth(true);
        
        dispatch({ type: "SET_ROLE", payload: selected_role});
    };

    function login(data,token,role,loginType) {
        data['token'] = token;
        dispatch({ type: "LOGIN", payload: {...data,role:role,loginType:loginType} });
        console.log(data)
        setTimeout(() => {
            navigateToCorrectPage(user);
        }, 1000);
    }

    const handleClose = () => {
        setOpenAuth(false);
    };
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    


    function logout() {    
        dispatch({ type: "LOGOUT" });
        
        window.location.href = "/";
    }
    const navigateToElement = (id) => {
        plausible.trackEvent('Random Button Click', {props: {element: id, component:'Header',page:window.location.pathname,user:user.id}});
    }
    


    //this functions logs in the user if the linkedin code is present in the url
    useEffect( () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code") || '';

        if (code==""|| user.loggedIn) return;

        
        
        const selected_role = user.selected_role || 'Customer'
 
        if (code && selected_role) {
            console.log(code, selected_role);
            console.log("sending login request")
            if(!selected_role){
                return;
            }
            axios
                .get(
                    `${NodeServer.baseURL}${NodeServer.getUserCredentials}?code=${code}&state=${selected_role}&redirect_uri=${LinkedInApi.redirectUrl}`
                )
                .then((response) => {
                    const role = response.data.user.recruiter ? "recruiter" : "customer";
                    login(response.data.user, response.data.token,role,"linkedin");
                    if(response.data.user.recruiter && window.location.pathname === "/"){
                        return window.location.href = "/email_verification";
                    }
                    
                    else if(response.data.user.customer && window.location.pathname === "/"){
                        window.location.href = "/email_verification";
                    }
                    

                })
                .catch((error) => {
                    const err = error?.response?.data.message;
                    alert(`ERROR: ${err}`);
                    console.log(error);
                    console.log(error.response.data.message);
                });
        }
    }, []);

    const navigateToRecruiterWelcome = () => {
        plausible.trackEvent('Header - Continue as Recruiter');
        window.location.href = "/recruiter_welcome";
    };


    //auto redirects user to the email verification page if the user is not verified
    useEffect(() => {
        if(window.location.href.includes("email") || window.location.href.includes('welcome')){
            return;
        }
        if(!user.email_verified && user.loggedIn && window.location.pathname != "/"){
            window.location.href = "/email_verification";
        }
    },[]);


    
    useEffect(() => {
        if(user.loggedIn){
            let today = new Date();
            let login_date = new Date(user.login_date);
            let diffdays = (today - login_date) / (1000 * 60 * 60 * 24 );
            if(diffdays >= 3){
                dispatch({type:'LOGOUT'});
                window.location.href = "/";
            }
        }
    },[]);
    
    const replaceImage = (error) => {
        //replacement of broken Image
        error.target.src = profile_placeholder;
    }

    return (
      <div className="px-[10%] py-4 items-center flex justify-between max-h-[90px] ">
        <img
          src={long_logo}
          alt=""
          onClick={()=>navigateToCorrectPage(user)}
          className="w-[55vw] aspect-auto  md:w-[318px] md:h-[58px] cursor-pointer"
        />
        <div className="flex align-middle sm:gap-0 lg:gap-[104px]  md:gap-[56px]">
          {isLandingPage && (
            <div className="gap-4 items-center hidden xl:flex">
              <a
                href="#Home"
                onClick={() => userSetSelected("Home")}
                className="py-[6px] px-[16px] rounded-xl hover:bg-[var(--green-100)]"
              >
                Home
              </a>
              <a
                href="#OurRecruiters"
                onClick={() => userSetSelected("Our Recruiters")}
                className="py-[6px] px-[16px] rounded-xl hover:bg-[var(--green-100)]"
              >
                Our Recruiters
              </a>
              <a
                href="#Services"
                onClick={() => userSetSelected("Services")}
                className="py-[6px] px-[16px] rounded-xl hover:bg-[var(--green-100)]"
              >
                Services
              </a>
              <a
                href="#HowItWorks"
                onClick={() => userSetSelected("How It Works")}
                className="py-[6px] px-[16px] rounded-xl hover:bg-[var(--green-100)]"
              >
                How It Works
              </a>
            </div>
          )}
        </div>

        {user.loggedIn ? (
          <Menu>
            <MenuButton as="div">
              <img
                src={
                  user.profileImageURL
                    ? user.profileImageURL
                    : profile_placeholder
                }
                alt="user profile"
                onClick={handleMenuOpen}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  maxHeight: "60px",
                  marginTop: "2px",
                }}
                onError={replaceImage}
              />
            </MenuButton>
            <MenuItems anchor="bottom end">
              <MenuItem
                as={"div"}
                className={
                  "cursor-pointer px-3 py-1 bg-white-normal hover:bg-green-50"
                }
                onClick={logout}
              >
                Logout
              </MenuItem>
            </MenuItems>
          </Menu>
        ) : (
          <div>
            <div className="hidden md:flex gap-[11px]">
              <button
                onClick={navigateToRecruiterWelcome}
                className="hover:bg-[var(--black-900)] text-black-900 hover:text-white-normal border border-black-100 bg-white-normal"
              >
                Join As Recruiter
              </button>

              <button onClick={openAuthPopup} className="">
                Login/SignUp
              </button>
            </div>
            <svg
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden cursor-pointer text-black-700 hover:text-[var(--green-700)]"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="non"
            >
              <path
                d="M20 12H10"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 5H4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 19H4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}

        {isMenuOpen && (
          <div className=" z-30 fixed  w-full h-full top-0 left-0 md:hidden">
            <div
              className="z-40 slideInFromLeft absolute w-[70%] h-full top-0 left-0 flex flex-col content-between "
              style={{ background: "#FFFFFF" }}
            >
              <div className=" p-4 flex justify-between items-center">
                <img src={long_logo} alt="" className="w-[90%] aspect-auto" />
                <svg
                  className="cursor-pointer  text-[#4A4C56] hover:text-branch"
                  onClick={() => setIsMenuOpen(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    d="M11.0003 17C10.7443 17 10.4883 16.9021 10.2933 16.7071L6.29325 12.7071C5.90225 12.3161 5.90225 11.684 6.29325 11.293L10.2933 7.29301C10.6842 6.90201 11.3163 6.90201 11.7073 7.29301C12.0983 7.68401 12.0983 8.31607 11.7073 8.70707L8.41434 12L11.7073 15.293C12.0983 15.684 12.0983 16.3161 11.7073 16.7071C11.5123 16.9021 11.2563 17 11.0003 17Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.4"
                    d="M17.0003 17C16.7443 17 16.4883 16.9021 16.2933 16.7071L12.2932 12.7071C11.9022 12.3161 11.9022 11.684 12.2932 11.293L16.2933 7.29301C16.6842 6.90201 17.3163 6.90201 17.7073 7.29301C18.0983 7.68401 18.0983 8.31607 17.7073 8.70707L14.4143 12L17.7073 15.293C18.0983 15.684 18.0983 16.3161 17.7073 16.7071C17.5123 16.9021 17.2563 17 17.0003 17Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col justify-between h-full pb-8">
                {isLandingPage && (
                  <div className=" flex-col flex gap-2 pl-5 pr-5">
                    <a
                      className="py-[6px] px-[16px]  hover:bg-[var(--green-100)]"
                      href="#home"
                      onClick={() => userSetSelected("Home")}
                    >
                      Home
                    </a>
                    <a
                      className="py-[6px] px-[16px]  hover:bg-[var(--green-100)]"
                      href="#OurRecruiters"
                      onClick={() => userSetSelected("Our Recruiters")}
                    >
                      Our Recruiters
                    </a>
                    <a
                      className="py-[6px] px-[16px]  hover:bg-[var(--green-100)]"
                      href="#Services"
                      onClick={() => userSetSelected("Services")}
                    >
                      Services
                    </a>
                    <a
                      className="py-[6px] px-[16px]  hover:bg-[var(--green-100)]"
                      href="#HowItWorks"
                      onClick={() => userSetSelected("How It Works")}
                    >
                      How It Works
                    </a>
                  </div>
                )}
                <div className="flex flex-col justify-center p-6 gap-[11px]">
                  <button className="hover:bg-[var(--black-900)] text-black-900 hover:text-white-normal border border-black-100 bg-white-normal">
                    Join As Recruiter
                  </button>
                  <button onClick={() => setOpenAuth(true)}>
                    Login/SignUp
                  </button>
                </div>
              </div>
            </div>
            <div
              onClick={() => setIsMenuOpen(false)}
              className="z-35 absolute w-full h-full top-0 right-0 md:hidden bg-green-900 opacity-[0.7]"
            ></div>
          </div>
        )}
        <AuthPopup open={openAuth} handleClose={handleClose} />
      </div>
    );
}



export default Header;