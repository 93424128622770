import axios from "axios";
import store from "../reducer/store";
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if(error.response.status === 401){
      console.log("INTERCEPOTOR, found 401 error")
      store.dispatch({ type: "LOGOUT" });
    }
    return Promise.reject(error);
  }
);

export default axios;
