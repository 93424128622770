import { plausible } from '../App';
const initialState = {
    loggedIn: false,
    sub:null,
    name:null,
    profileImageURL:null,
    selected_role:null,
    login_date: null
};
const user = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            plausible.trackEvent('Login', {props: {loginType:action.payload.loginType, userID: action.payload.id, page: window.location.pathname}});
            return {
                ...state,
                loggedIn: true,
                sub:'1',
                email_verified: action.payload.recruiter? action.payload.recruiter.email_verified : action.payload.customer.email_verified,
                ...action.payload,
                login_date: new Date().toISOString()
            };
        case 'SET_ROLE':
            return {
                ...state,
                selected_role: action.payload
            };
        case 'LOGOUT':
            console.log("Logging out")
            plausible.trackEvent('Logout', {props: {userID:state.id, page: window.location.pathname}});
            
            return initialState;

        case 'UPDATE_RECRUITER':
            return {
                ...state,
                recruiter: {
                    ...state.recruiter,
                    ...action.payload
                }
            };
        case 'UPDATE_CUSTOMER':
            return {
                ...state,
                customer: {
                    ...state.customer,
                    ...action.payload
                }
            };


        case 'UPDATE_CUSTOMER_EMAIL':
            return {
                ...state,
                customer: {
                    ...state.customer,
                    email: action.payload
                }
            };
        case 'EMAIL_VERIFIED':
            return {
                ...state,
                email_verified: 1
            }
        default:
            return state;
    }
};

export default user;
