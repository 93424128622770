import React, { useEffect, useState } from 'react';
import {useSelector } from 'react-redux';
import {reportRecruiter} from '../api/customer';
import { plausible } from '../App';
import { Dialog, DialogPanel } from '@headlessui/react';
import { CancelButtonStyle, DialogBackgroundStyle, DialogPanelStyle, smallDialogPanelStyle } from '../constants';
export default function Report({open,handleClose,type,orderID,recruiterID,customerID}) {
    const [report, setReport] = useState({reportReason: '', reportContent: ''});
    const [reportSubmitted, setReportSubmitted] = useState(false);
    const user = useSelector((state) => state.user);
    const handleReportChange = (event) => {
        setReport({...report, [event.target.name]: event.target.value});
    };

    const handleSubmit = async () => {
        if(reportSubmitted) return;
        // Perform submit logic here
        console.log("Report:", report);
        const reportObj = {
            report,
            reportFrom: user.role,
            reportTarget: type,
            reportType: type,
            orderID,
            recruiterID,
            customerID
        };
        console.log(reportObj)
        reportRecruiter(type,reportObj).then((response) => {
            console.log("Report response:", response);
            setReportSubmitted('true')
        });
    };

    const [timer, setTimer] = useState(null);
    useEffect(() => {
        if(open){
            setTimer(new Date().getTime());
        }
        if(!open && timer){
            plausible.trackEvent('Report Dialog', {props: {time: new Date().getTime() - timer,recruiterID:recruiterID,customerID:customerID, page: window.location.pathname}});
            setTimer(null);
        }
        setReport({reportReason: '', reportContent: ''});
        setReportSubmitted(false);
    }, [open]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} className={DialogBackgroundStyle}>
                <DialogPanel style={{width:'350px', display:'flex',flexDirection:'column',gap:'20px'}} className={smallDialogPanelStyle}>
                            <h2 variant="h6">Report {type}</h2>
                            <p >Please provide a reason for your report</p>
                            <textarea
                                
                                variant="outlined"
                                name='reportReason'
                                placeholder="e.g Didn't complete order, is rude, etc."
                                fullWidth
                                multiline
                                rows={3}
                                inputProps={{ maxLength: 150 }}
                                value={report.reportReason}
                                onChange={handleReportChange}
                            />
                            <p >Please provide more details</p>
                            <textarea
                                label="What happened?"
                                variant="outlined"
                                name="reportContent"
                                placeholder='Example: The resume was not delivered as promised, the recruiter was rude, etc.'
                                fullWidth
                                multiline
                                rows={8}
                                inputProps={{ maxLength: 1000 }}
                                value={report.reportContent}
                                onChange={handleReportChange}
                            />

                            <button variant="contained" color="primary" onClick={handleSubmit} disabled={report.reportContent.length===0}>
                                {reportSubmitted? "Report Submitted!": "Submit Report"}
                            </button>
                            {reportSubmitted && <p variant="h6" color="success">We will look into this very soon and get back to you through your email</p>}
                            <button className={CancelButtonStyle} onClick={handleClose}>
                                {reportSubmitted? "Close": "Cancel"}
                            </button>
                </DialogPanel>
            </Dialog>
        </div>
    );
}
