import React, { useState } from "react";
import landingpage from "../../../assets/landingpage.jpg";
import RecruiterWelcomeImg from "../../../assets/RecruiterWelcomeImg.png";
import landingpage_service_meeting from "../../../assets/landingpage_service_meeting.png";
import landingpage_service_revision from "../../../assets/landingpage_service_revision.png";
import landingpage_service_review from "../../../assets/landingpage_service_review.png";
import FAQSection from './FAQSection';
import long_logo_singleline from '../../../assets/long_logo_singleline.png';
import mini_icon_3 from "../../../assets/mini icon 3.png";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import AuthPopup from '../../../Auth/Auth_popup';

const Recruiter_welcome = () => {


      const user = useSelector((state) => state.user);

      const [openAuth, setOpenAuth] = useState(false);

      const dispatch = useDispatch();

      function openAuthPopup() {
        if (!user.loggedIn) {
          setOpenAuth(true);
          dispatch({ type: "SET_ROLE", payload: "recruiter" });
          return;
        }
        if (user.recruiter.name) {
          window.location.href = "/recruiter_dashboard";
        } else {
          window.location.href = "/onboarding";
        }
      }
      const closeAuth = () => {
        setOpenAuth(false);
      };


  return (
    <div className="flex flex-col items-center">
      <AuthPopup open={openAuth} handleClose={closeAuth} />
      <div
        className="h-[550px] lg:h-[708px] w-full"
        style={{
          backgroundImage: `url(${landingpage})`,
          backgroundPositionX: "20%",
          backgroundPositionY: "30%",
          backgroundSize: "250% 150%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className=" h-full w-full p-4 bg-[#031411] bg-opacity-60">
          <div className="flex flex-col  lg:flex-row  gap-4 absolute top-[20%] lg:top-[25%] left-[5vw] lg:left-[15vw] min-w-[340px] w-[90vw] lg:w-[70vw]  ">
            <div className="max-w-[717px]">
              <div className="flex items-center gap-2">
                <div className="w-[20px] md:w-[59px] rounded-xl h-[2px] bg-green-600"></div>
                <p className="text-[#D0F9E0] ">Recruiter Welcome</p>
              </div>

              <h1 className="text-white-normal my-4 text-[36px] md:text-[48px] xl-text-[64px]">
                What is RecruiterService?
              </h1>
              <p className="text-[#F9FBF9] text-[18px]">
                RecruiterService is an online platform that allows recruiters to
                review and revise resumes, and talk to job-seekers who are
                interested in a career in your industry. Who better to review
                and give direct feedback to people than an actual recruiter from
                the company they want to join?
              </p>
              <button className="mt-[30px] w-[250px]" onClick={openAuthPopup}>
                Join as a Recruiter Now
              </button>
            </div>
            <img
              src={RecruiterWelcomeImg}
              aria-hidden
              alt=""
              className="rounded-[40px] mr-[5vw] hidden lg:flex self-center max-h-[560px]"
            />
          </div>
        </div>
      </div>
      <div className="bg-[#E9FAF7] w-full flex flex-col  gap-5 justify-center px-[30px] py-[50px] md:px-[15vw]   md:py-[100px]">
        <h1>How it works?</h1>
        <p className="text-[24px] md:text-[32px] max-w-[1073px]">
          You create a profile stating the companies you worked for and your
          experiences, then just sit back, relax, and wait for orders to flow
          into your email, complete it, get paid. Thats it!
        </p>
      </div>
      <div className="flex flex-col w-full justify-items-center  align-middle px-[20px] py-[56px] md:px-[30px] md:py-[90px] xl:px-[300px] xl:py-[120px]">
        <div className="flex  flex-col items-center">
          <h1 className="text-[28px] mg:text-[36px] xl:text-[40px]">
            Why work through RecruiterService?
          </h1>
          <p className="text-[16px] lg:text-[18px] mt-5 max-w-[676px]">
            Quick Earnings, Flexible Scheduling, and Genuine Career Guidance.
            Join Us Today and Elevate Your Recruiting Experience
          </p>
        </div>

        <div className="flex flex-wrap mt-[56px] gap-x-4 justify-center gap-y-8">
          <div className="flex flex-wrap justify-center ">
            <div className="relative flex gap-[16px] max-w-[335px] lg:flex-col lg:gap-[44px] lg:max-w-[419px]">
              <div className="flex-none rounded-full p-[18px] bg-[#B5E4CA] w-[66px] h-[66px] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 9C6 5.68629 8.68629 3 12 3V3C15.3137 3 18 5.68629 18 9V15C18 18.3137 15.3137 21 12 21V21C8.68629 21 6 18.3137 6 15V9Z"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 7L12 11"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p className=" absolute hidden lg:flex right-[144px] top-[-10px] text-[80px] font-bold tracking-[-3.2px] opacity-[0.08] leading-none">
                01
              </p>
              <div>
                <h3 className="text-[#1D1E25] text-[20px] lg:text-[24px] font-semibold tracking-[-0.5px]">
                  Quick Service for Easy Money
                </h3>
                <p className="text-[#031411] opacity-60">
                  Your earnings will be 80% of what the customer pays after 3%
                  payment processing fees.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-center ">
            <div className="relative flex gap-[16px] max-w-[335px] lg:flex-col lg:gap-[44px] lg:max-w-[419px]">
              <div className="flex-none rounded-full p-[18px] bg-[#FFBC99] w-[66px] h-[66px] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2 2V1C1.44772 1 1 1.44772 1 2H2ZM12.1953 9.52866L11.2842 9.1166C11.1127 9.49577 11.194 9.94151 11.4882 10.2358L12.1953 9.52866ZM14 11.3333L14.7071 12.0404C14.8946 11.8529 15 11.5985 15 11.3333C15 11.0681 14.8946 10.8138 14.7071 10.6262L14 11.3333ZM11.3333 14L10.6262 14.7071C10.8138 14.8946 11.0681 15 11.3333 15C11.5985 15 11.8529 14.8946 12.0404 14.7071L11.3333 14ZM9.52866 12.1953L10.2358 11.4882C9.94151 11.194 9.49577 11.1127 9.1166 11.2842L9.52866 12.1953ZM7.33333 1H2V3H7.33333V1ZM13.6667 7.33333C13.6667 3.83553 10.8311 1 7.33333 1V3C9.72657 3 11.6667 4.9401 11.6667 7.33333H13.6667ZM13.1065 9.94072C13.4667 9.14428 13.6667 8.26081 13.6667 7.33333H11.6667C11.6667 7.97102 11.5296 8.57399 11.2842 9.1166L13.1065 9.94072ZM11.4882 10.2358L13.2929 12.0404L14.7071 10.6262L12.9024 8.82155L11.4882 10.2358ZM13.2929 10.6262L10.6262 13.2929L12.0404 14.7071L14.7071 12.0404L13.2929 10.6262ZM12.0404 13.2929L10.2358 11.4882L8.82155 12.9024L10.6262 14.7071L12.0404 13.2929ZM7.33333 13.6667C8.26081 13.6667 9.14428 13.4667 9.94072 13.1065L9.1166 11.2842C8.57399 11.5296 7.97102 11.6667 7.33333 11.6667V13.6667ZM1 7.33333C1 10.8311 3.83553 13.6667 7.33333 13.6667V11.6667C4.9401 11.6667 3 9.72657 3 7.33333H1ZM1 2V7.33333H3V2H1Z"
                    fill="#030304"
                  />
                  <ellipse
                    cx="7.33366"
                    cy="7.33329"
                    rx="1.33333"
                    ry="1.33333"
                    transform="rotate(-180 7.33366 7.33329)"
                    fill="#030304"
                  />
                  <path
                    d="M2 2L7.33333 7.33333"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p className="absolute hidden lg:flex right-[144px] top-[-10px] text-[80px] font-bold tracking-[-3.2px] opacity-[0.08] leading-none">
                02
              </p>
              <div>
                <h3 className="text-[#1D1E25] text-[20px] lg:text-[24px] font-semibold tracking-[-0.5px]">
                  Flexible Schedule
                </h3>
                <p className="text-[#031411] opacity-60">
                  You work whenever you want, be it during a coffee break, lunch
                  break, or weekend. For one on one meetings you get to choose
                  from all the aval•able time slots that the customer has
                  provided.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-center">
            <div className="relative flex gap-[16px] max-w-[335px] lg:flex-col lg:gap-[44px] lg:max-w-[419px]">
              <div className="flex-none rounded-full p-[18px] bg-[#CABDFF] w-[66px] h-[66px] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.78711 11.2867L10.5004 14L11.6938 12.8067C11.7632 12.7374 11.8166 12.6538 11.85 12.5616C11.8834 12.4694 11.8961 12.371 11.8871 12.2733L11.4538 7.53333"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.24695 3.14L5.33362 4.08667M6.24695 11.64C6.30893 11.7025 6.38266 11.7521 6.4639 11.7859C6.54514 11.8198 6.63228 11.8372 6.72028 11.8372C6.80829 11.8372 6.89543 11.8198 6.97667 11.7859C7.05791 11.7521 7.13164 11.7025 7.19362 11.64L12.467 6.36667C13.4478 5.38503 13.9993 4.05438 14.0003 2.66667C14.0003 2.48986 13.93 2.32029 13.805 2.19526C13.68 2.07024 13.5104 2 13.3336 2C11.9459 2.00101 10.6153 2.55245 9.63362 3.53333L4.36028 8.80667C4.2978 8.86864 4.2482 8.94238 4.21436 9.02362C4.18051 9.10486 4.16309 9.19199 4.16309 9.28C4.16309 9.36801 4.18051 9.45515 4.21436 9.53638C4.2482 9.61762 4.2978 9.69136 4.36028 9.75333L6.24695 11.64ZM11.9136 10.6667L12.8603 9.72667L11.9136 10.6667Z"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 4.54661L3.72667 4.11328C3.62902 4.10429 3.5306 4.11697 3.43841 4.15038C3.34622 4.1838 3.26254 4.23714 3.19333 4.30661L2 5.49994L4.72667 8.22661"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p className="absolute hidden lg:flex right-[144px] top-[-10px] text-[80px] font-bold tracking-[-3.2px] opacity-[0.08] leading-none">
                03
              </p>
              <div>
                <h3 className="text-[#1D1E25] text-[20px] lg:text-[24px] font-semibold tracking-[-0.5px]">
                  Provides Genuine Service
                </h3>
                <p className="text-[#1D1F2C] opacity-60">
                  We can all remember being unsure of our resumes. While
                  advisors and friends help, they are not you, the recruiter! To
                  have access to you not only clears the doubts of job-seekers,
                  but helps provide valuable career advice to them as well as
                  giving them a resume that will help them stand out from the
                  crowd.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Services"
        className="bg-black-900 flex justify-center w-full py-[56px] px-4  md:py-[80px] md:px-[80px] xl:px-[200px] lg:py-[100px] xxl:px-[300px]"
      >
        <div className="w-full">
          <div>
            <h1 className="text-white-normal mb-3">Services</h1>
            <p className="text-[#E9E9EA] opacity-[0.6]">
              As a recruiter, you can provide the following services and more to
              come in the future!
            </p>
          </div>

          <div className="flex flex-wrap gap-[30px] mt-[56px] max-w-[1320px] justify-center items-start">
            <div
              className="flex flex-col   gap-[28px] rounded-[40px] hover:bg-[#E9FAF7]  bg-white-normal w-[370px] sm:w-[420px] p-5 sm:p-10 sm:pt-[60px]"
              onClick={openAuthPopup}
            >
              <img
                src={landingpage_service_review}
                width={"120px"}
                className="h-[120px]"
                alt=""
              />
              <h3 className="text-[24px] text-[#030304] font-semibold">
                Resume Review
              </h3>
              <p className="text-[18px] text-[#1D1F2C] font-medium">
                You are given a job posting and a resume and you just review
                that resume & answer a few questions
                <br />
                <br />- Will it pass ATS?
                <br />- Will you move it to the phone interview?
                <br />- Ways to improve?
              </p>
              <p
                className="text-[18px] text-branch font-medium cursor-pointer"
                onClick={openAuthPopup}
              >
                You get: $15 to $25
              </p>
            </div>
            <div
              className="flex flex-col gap-[28px] rounded-[40px] hover:bg-[#E9FAF7]  bg-white-normal w-[370px] sm:w-[420px] p-5 sm:p-10 sm:py-[60px]"
              onClick={openAuthPopup}
            >
              <img
                src={landingpage_service_revision}
                width={"120px"}
                className="h-[120px]"
                alt=""
              />
              <h3 className="text-[24px] text-[#030304] font-semibold ">
                Resume Revision
              </h3>
              <p className="text-[18px] text-[#1D1F2C] font-medium">
                You are given a job posting and a resume and you fit the resume
                to the job posting so that the customer have a higher chance of
                getting that interview.
                <br />
                <br />- Keyword Optimization
                <br />- Tailoring Experience and Achievements.
                <br />- Formatting and Presentation.
                <br />- Highlighting Transferable Skills.
              </p>
              <p className="text-[18px] text-branch font-medium cursor-pointer">
                You get: $80 to $120
              </p>
            </div>
            <div
              className="flex flex-col gap-[28px] hover:bg-[#E9FAF7] rounded-[40px] bg-white-normal w-[370px] sm:w-[420px] p-5 sm:p-10 sm:py-[60px]"
              onClick={openAuthPopup}
            >
              <img
                src={landingpage_service_meeting}
                width={"120px"}
                className="h-[120px]"
                alt=""
              />
              <h3 className="text-[24px] text-[#030304] font-semibold">
                One-on-one Meeting
              </h3>
              <p className="text-[18px] text-[#4A4C56] font-medium">
                Just the customer asking for your time, it will be a 20-40
                minute meeting
                <br />
                <br />- Customer will let you know what the meeting will be
                about, whether consulting, review, or mock phone interview.
                <br />- You will indicate times avaliable for customers to
                choose from.
                <br />- Customer will pick multiple dates and time ranges for
                you to choose the most convenient one.
                <br />- Meeting will be held on Zoom.
              </p>
              <p className="text-[18px] text-branch font-medium cursor-pointer">
                You get: $50 per 20 minutes
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center  m-auto my-[56px] mx-4  md:my-[80px] md:mx-[80px] xl:mx-[200px] lg:my-[100px] max-w-[1094px]  xxl:mx-[300px]">
        <div className="w-full flex flex-col  gap-5 justify-center px-[30px] py-[30px] md:px-[15vw]  md:py-[50px] text-center ">
          <h2>Things You Should Know</h2>
          <p className="text-[18px] max-w-[1073px]">
            We are happy to answer your questions
          </p>
        </div>
        <FAQSection />
        <button className="mt-[30px] w-[250px]" onClick={openAuthPopup}>
          Join as a Recruiter Now
        </button>
      </div>
      <div className="w-full bg-[#3C8157] flex flex-col pl-4 py-[56px] md:py-[90px] md:px-[80px] xl:py-[120px] xl:px-[200px] relative overflow-hidden">
        <img
          src={long_logo_singleline}
          className="rounded-xl w-[220px] mb-6 cursor-pointer"
          alt=""
          draggable="false"
          onClick={() => document.getElementById("Home").scrollIntoView()}
        />
        <h1 className="text-white-normal max-w-[943px]">
          Let’s join Recruiter Service,
          <br /> Improve Job Seeker's Application
        </h1>
        <div className="flex items-center mt-9 gap-3">
          <div className="rounded-full bg-white-normal/10 size-[45px] flex items-center justify-center">
            <EnvelopeIcon className="size-6 text-white-normal" />
          </div>
          <p className="text-white-normal text-[18px]">
            support@recruiterservice.net
          </p>
        </div>
        <img
          src={mini_icon_3}
          className="absolute select-none top-0 right-[-20%] lg:right-[-10%] xl:right-[0%] z-0"
          aria-hidden
          alt=""
          draggable="false"
        />
      </div>
    </div>
  );
};

export default Recruiter_welcome;
