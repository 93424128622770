import React, { useState } from "react";
import {
  uploadOnboardingData,
  uploadProfileImage,
  uploadCompanyLogo,
} from "../../../api/recruiter";
import { useSelector, useDispatch } from "react-redux";
import celebration from "../../../assets/celebration.gif";
import googleLogo from "../../../assets/companyLogos/google_logo.png";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import RecruiterCard from "../../../reusables/RecruiterCard";
import adidasLogo from "../../../assets/companyLogos/addidas_logo.png";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { Input } from "@headlessui/react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { DialogBackgroundStyle } from "../../../constants";
function Onboarding() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    recruiter_id: user.recruiter.user_id,
    name: user.recruiter.name || "",
    linkedIn_profile_link: user.recruiter.linkedIn_profile_link || "",
    company_list: user.recruiter.company_list || "",
    company_logo: user.recruiter.company_logo || "",
    job_title: user.recruiter.job_title || "",
    is_technical: user.recruiter.is_technical || "0",
    display_linkedIn: user.recruiter.display_linkedIn || "1",
    profile_image: user.recruiter.profile_image || "",
    service_quick_review: user.recruiter.service_quick_review || "",
    service_revision: user.recruiter.service_revision || "",
    service_meeting: user.recruiter.service_meeting || "",
    years_of_experience: user.recruiter.years_of_experience || "",
    hiring_in_these_industries: user.recruiter.hiring_in_these_industries,
    short_description: user.recruiter.short_description || "",
    most_frequently_hired_roles:
      user.recruiter.most_frequently_hired_roles || "",
  });
  const animatedComponents = makeAnimated();
  const [previewImg, setPreviewImg] = useState(inputValues.profile_image);
  const [previewLogo, setpreviewLogo] = useState(inputValues.company_logo);
  const [saved, setSaved] = useState(false);
  const [openThankYouDialog, setOpenThankYouDialog] = useState(false);

  const handleInputChange = (event) => {
    let { name, value, type, checked } = event.target;
    if (value === "Yes" || value === "No") {
      value = value === "Yes" ? "1" : "0";
      console.log(value);
    }
    if (type === "checkbox") {
      setInputValues((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleProfileImageUpload = async (e) => {
    setPreviewImg("");
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.type.split("/")[0] !== "image") {
      alert("Please upload an image file");
      return;
    }
    if (file.size > 15242880) {
      alert("File size too large. profile image should not be more than 15MB");
      e.target.files[0] = null;
      return;
    }
    const formData = new FormData();
    const imageB64Url = await convertImageToBase64(file);
    setPreviewImg(imageB64Url);
    formData.append("file", file);
    formData.append("recruiter_id", user.recruiter.user_id);
    const imageURL = await uploadProfileImage(formData);
    if (imageURL) {
      setInputValues((prevState) => ({
        ...prevState,
        profile_image: imageURL.url,
      }));
      console.log("image uploaded: ", imageURL);
      alert("image uploaded successfully");
      dispatch({
        type: "UPDATE_RECRUITER",
        payload: { profile_image: imageURL.url },
      });
    } else {
      alert(
        "image upload failed, it might be too big or a server problem, please try again later."
      );
    }
  };

  const handleLogoUpload = async (e) => {
    setpreviewLogo("");
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > 5242880) {
      alert("File size too large. Logos should not be more than 5MB");
      e.target.files[0] = null;
      return;
    }
    const formData = new FormData();
    const imageB64Url = await convertImageToBase64(file);
    setpreviewLogo(imageB64Url);
    formData.append("file", file);
    formData.append("recruiter_id", user.recruiter.user_id);
    const imageURL = await uploadCompanyLogo(formData);
    if (imageURL) {
      setInputValues((prevState) => ({
        ...prevState,
        company_logo: imageURL,
      }));
      console.log("logo uploaded: ", imageURL);
      dispatch({
        type: "UPDATE_RECRUITER",
        payload: { company_logo: imageURL },
      });
      alert("logo uploaded successfully");
    } else {
      alert(
        "image upload failed, it might be too big or a server problem, please try again later."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputValues);
    delete inputValues["linkedIn_profile_link"];
    const uploadedSuccessfully = await uploadOnboardingData(inputValues);
    console.log(uploadedSuccessfully);
    if (!uploadedSuccessfully) {
      alert("upload failed, please try again later");
      return;
    }
    setSaved(true);
    setOpenThankYouDialog(true);
    dispatch({ type: "UPDATE_RECRUITER", payload: inputValues });
  };

  async function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        let base64 = fileReader.result;

        resolve(base64);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const handleUpdateIndustries = (event) => {
    setInputValues((prevState) => ({
      ...prevState,
      hiring_in_these_industries: event
        .map((industry) => industry.value)
        .join(","),
    }));
    console.log(event.map((industry) => industry.value).join(","));
  };

  const industrySelectOptions = [
    { value: "Software Development", label: "Software Development" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Medical Services", label: "Medical Services" },
    { value: "Finance", label: "Finance" },
    { value: "Accounting", label: "Accounting" },
    { value: "Education", label: "Education" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Retail", label: "Retail" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Construction", label: "Construction" },
    { value: "Engineering", label: "Engineering" },
    { value: "Marketing", label: "Marketing" },
    { value: "Advertising", label: "Advertising" },
    { value: "Consulting", label: "Consulting" },
    { value: "Automotive", label: "Automotive" },
    { value: "Pharmaceuticals", label: "Pharmaceuticals" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Energy", label: "Energy" },
    { value: "Utilities", label: "Utilities" },
    { value: "Aerospace", label: "Aerospace" },
    { value: "Defense", label: "Defense" },
    { value: "Transportation", label: "Transportation" },
    { value: "Logistics", label: "Logistics" },
    { value: "Media", label: "Media" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Biotechnology", label: "Biotechnology" },
    { value: "Insurance", label: "Insurance" },
    { value: "Government", label: "Government" },
    { value: "Public Sector", label: "Public Sector" },
    { value: "Legal", label: "Legal" },
    { value: "Nonprofit", label: "Nonprofit" },
    { value: "NGO", label: "NGO" },
    { value: "Food and Beverage", label: "Food and Beverage" },
    { value: "Fashion", label: "Fashion" },
    { value: "Apparel", label: "Apparel" },
    { value: "Gaming", label: "Gaming" },
    { value: "Sports", label: "Sports" },
    { value: "Recreation", label: "Recreation" },
    { value: "Architecture", label: "Architecture" },
    { value: "Environmental Services", label: "Environmental Services" },
    { value: "Market Research", label: "Market Research" },
    { value: "Recruitment", label: "Recruitment" },
    { value: "Staffing", label: "Staffing" },
    { value: "Travel", label: "Travel" },
    { value: "Tourism", label: "Tourism" },
    { value: "E-commerce", label: "E-commerce" },
    { value: "Human Resources", label: "Human Resources" },
  ];

  return (
    <div className="w-[100vw] flex justify-center mb-8">
      <div className="w-full items-center flex flex-col gap-5">
        <div className="flex w-full flex-col items-center gap-4 bg-green-50 py-[30px] lg:py-[90px] px-3">
          <h1>Onboarding page</h1>
          <p>
            Please fill out the following information to complete the onboarding
            process
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-5 max-w-[800px]"
        >
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <h3> 1: Please upload a profile image*</h3>
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    {previewImg ? (
                      "Uploaded!"
                    ) : (
                      <span>
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </span>
                    )}
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleProfileImageUpload}
                />
              </label>
            </div>

            {previewImg && (
              <div className="w-full flex flex-col items-center">
                <h3>Your Profile Image:</h3>
                <img
                  src={previewImg}
                  alt="preview"
                  className="bg-transparent"
                  style={{
                    maxHeight: "150px",
                    maxWidth: "200px",
                    marginTop: "10px",
                    borderRadius: "10px",
                    border: "1px solid black",
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-5">
            <h3>2: Please fill out the following information</h3>

            <div>
              <label htmlFor="name">What is your full name?*</label>
              <br />
              <input
                id="name"
                name="name"
                placeholder="name"
                className="w-full max-w-[400px]"
                maxLength="50"
                required
                value={inputValues.name}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="job_title">What is your job title?*</label>
              <br />
              <input
                id="job_title"
                name="job_title"
                placeholder="recruiter, talent acquisition specialist, etc."
                className="w-full max-w-[400px]"
                variant="outlined"
                maxLength="100"
                required
                value={inputValues.job_title}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="company_list">
                What are the companies you recruited for (up to 3)?*
              </label>
              <br />
              <input
                id="company_list"
                name="company_list"
                variant="outlined"
                placeholder="example: Google, Meta, Amazon"
                className="w-full max-w-[400px]"
                maxLength="200"
                required
                value={inputValues.company_list}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="most_frequently_hired_roles">
                {" "}
                Which roles do you most frequently hire for? (with comma
                separation)*
              </label>
              <br />
              <Input
                id="most_frequently_hired_roles"
                name="most_frequently_hired_roles"
                label="Which roles do you most frequently hire for? (with comma separation)"
                placeholder="example: Software Engineer, Product Manager, Frontend Developer, Talent Aquisition Specialist, UX Designer, etc."
                variant="outlined"
                className="w-full"
                maxLength="300"
                required
                value={inputValues.most_frequently_hired_roles}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="years_of_experience">
                How many years of recruiting experience do you have?*
              </label>
              <br />
              <input
                id="years_of_experience"
                name="years_of_experience"
                label="How many years of experience do you have as a recruiter?"
                variant="outlined"
                value={inputValues.years_of_experience}
                type="number"
                required
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="is_technical">
                Are you a technical recruiter?*
              </label>
              <br />
              <select
                id="is_technical"
                name="is_technical"
                className="w-full max-w-[400px]"
                value={inputValues.is_technical}
                onChange={handleInputChange}
              >
                <option value="None"></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            <div>
              <label>
                Which industries have you primarily hired for?* (pick 5)
              </label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                label="Which industries do you hire in?"
                value={
                  inputValues.hiring_in_these_industries
                    ? inputValues.hiring_in_these_industries
                        .split(",")
                        .map((industry) => {
                          return { value: industry, label: industry };
                        })
                    : null
                }
                isOptionDisabled={() =>
                  inputValues.hiring_in_these_industries
                    ? inputValues.hiring_in_these_industries.split(",")
                        .length >= 5
                    : false
                }
                isMulti
                options={industrySelectOptions}
                onChange={(event) => {
                  handleUpdateIndustries(event);
                }}
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
              />
            </div>

            <div>
              <label htmlFor="short_description">
                Can you give a short description of yourself and your
                experiences?*
              </label>
              <textarea
                id="short_description"
                name="short_description"
                label="Give a short description of yourself and your experiences"
                placeholder="This is bascially your sales pitch on why customers should pick you"
                className="w-full"
                maxLength={500}
                required
                value={inputValues.short_description}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          {/* <p variant="h6">
            Please upload company logo of the company you want to appear on your
            profile
          </p>
          <p variant="p">
            Our user studies show that profiles with company logo present gets
            3x more clicks. It is very signifigant! However it is not required.
          </p>
          <p variant="p">Example: </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <img
              src={googleLogo}
              alt="google logo"
              style={{ maxWidth: "70px", maxHeight: "70px", marginTop: "10px" }}
            />
            <img
              src={adidasLogo}
              alt="adidas logo"
              style={{ maxHeight: "70px", marginTop: "10px" }}
            />
          </div>
          <button
            variant="contained"
            component="label"
            style={{
              width: "310px",
              height: "150px",
              marginTop: "10px",
              fontSize: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UserCircleIcon style={{ fontSize: "50px" }} />
            {previewLogo ? "Uploaded!" : "Upload Company Logo"}
            <input
              type="file"
              accept="image/*"
              onChange={handleLogoUpload}
              hidden
            />
          </button>
          {previewLogo && (
            <img
              src={previewLogo}
              alt="preview"
              style={{ maxWidth: "70px", maxHeight: "70px", marginTop: "10px" }}
            />
          )} */}
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: inputValues.short_description ? "flex" : "none",
              marginTop: "20px",
            }}
          >
            <h3 variant="h6">Preview:</h3>
            {inputValues.short_description && (
              <RecruiterCard recruiter={inputValues} preview={true} />
            )}
            <p variant="h6">
              Please make sure all the information is correct and profile looks
              good before submitting
            </p>
          </div>
          <div className="flex flex-col items-center justify-center w-full">
            <input
              style={{ width: "200px" }}
              type="submit"
              disabled={
                !(
                  inputValues.profile_image.includes("recruiterservice") &&
                  inputValues.short_description &&
                  inputValues.most_frequently_hired_roles &&
                  inputValues.years_of_experience &&
                  inputValues.profile_image &&
                  inputValues.name &&
                  inputValues.company_list &&
                  inputValues.job_title &&
                  inputValues.is_technical &&
                  inputValues.display_linkedIn
                )
              }
              className="bg-branch text-white-normal hover:bg-branch-hover 0 hover:text-black-900 text-white p-2 rounded-md disabled:bg-red-300 disabled:cursor-not-allowed disabled:text-gray-500"
            ></input>
            {saved && (
              <div className="text-center">
                <p>Saved successfully!</p>
                <p>
                  We will send you an email if an customer requests your service
                </p>
                <br />
              </div>
            )}
          </div>
          {/* </Tooltip> */}
          {saved && (
            <Dialog
              open={openThankYouDialog}
              onClose={() => setOpenThankYouDialog(false)}
              className={DialogBackgroundStyle}
            >
              <DialogPanel className=" fixed space-y-4 border bg-white p-6 bg-white-normal w-full max-w-[600px] gap-6 rounded-xl">
                <img
                  src={celebration}
                  alt="celebration"
                  style={{ width: "100%", height: "auto" }}
                />
                <p variant="h5">
                  Thank you so much for signing up and doing the onboarding, you
                  will hear from us through email soon!
                </p>
              </DialogPanel>
            </Dialog>
          )}
        </form>
      </div>
    </div>
  );
}

export default Onboarding;
