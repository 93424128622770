import React, { useEffect, useState } from "react";

import {
  uploadCompanyLogo,
  uploadProfileImage,
  uploadOnboardingData,
} from "../../api/recruiter";
import { Dialog, DialogPanel } from "@headlessui/react";
import { DialogBackgroundStyle, DialogPanelStyle } from "../../constants";
export default function Edit_recruiter({ open, handleClose, recruiter }) {
  const [formData, setFormData] = useState({ ...recruiter });

  const [previewImg, setPreviewImg] = useState("");
  const [previewLogo, setpreviewLogo] = useState("");
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (recruiter) {
      delete recruiter["linkedIn_profile_link"];
      delete recruiter["user_type"];
      delete recruiter["is_test"];
      delete recruiter["email"];
      delete recruiter["email_verified"];
      delete recruiter["last_login_date"];
      delete recruiter["service_quick_review"];
      delete recruiter["service_revision"];
      delete recruiter["service_meeting"];
      delete recruiter["active_orders"];
      delete recruiter["number_of_orders"];
      delete recruiter["profile_clicked"];
      delete recruiter["average_response_time"];
      delete recruiter["order_fullfillment_rate"];
      delete recruiter["average_rating"];
      delete recruiter["activated"];
      delete recruiter["approved"];
      delete recruiter["resume"];
      delete recruiter["sign_up_method"];
    }

    setFormData({ ...recruiter });
  }, [recruiter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    const uploadedSuccessfully = await uploadOnboardingData(formData);

    if (!uploadedSuccessfully) {
      alert("upload failed, please try again later");
      return;
    } else {
      alert("onboarding data uploaded successfully");
      window.location.reload();
    }
  };
  const handleProfileImageUpload = async (e) => {
    setPreviewImg("");
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > 15242880) {
      alert("File size too large. profile image should not be more than 15MB");
      e.target.files[0] = null;
      return;
    }
    const formData = new FormData();
    const imageB64Url = await convertImageToBase64(file);
    setPreviewImg(imageB64Url);
    formData.append("file", file);
    formData.append("recruiter_id", recruiter.user_id);
    const imageURL = await uploadProfileImage(formData);
    if (imageURL) {
      alert("image uploaded successfully");
      window.location.reload();
    } else {
      alert(
        "image upload failed, it might be too big or a server problem, please try again later."
      );
    }
  };
  async function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        let base64 = fileReader.result;

        resolve(base64);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const handleLogoUpload = async (e) => {
    setpreviewLogo("");
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > 5242880) {
      alert("File size too large. Logos should not be more than 5MB");
      e.target.files[0] = null;
      return;
    }
    const formData = new FormData();
    const imageB64Url = await convertImageToBase64(file);
    setpreviewLogo(imageB64Url);
    formData.append("file", file);
    formData.append("recruiter_id", recruiter.user_id);
    const imageURL = await uploadCompanyLogo(formData);
    if (imageURL) {
      console.log("image uploaded: ", imageURL);
      alert("logo uploaded successfully");
      window.location.reload();
    } else {
      alert(
        "image upload failed, it might be too big or a server problem, please try again later."
      );
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} className={DialogBackgroundStyle}>
      <DialogPanel className={DialogPanelStyle}>
        <form onSubmit={handleSubmit} className="flex flex-col">
          {Object.entries(formData).map(([attribute, value]) =>
            attribute === "company_logo" || attribute === "profile_image" ? (
              <div>
                <p >{attribute} </p>
                <div>
                  <input
                    type="file"
                    name={attribute}
                    onChange={(e) => {
                      if (attribute === "company_logo") {
                        handleLogoUpload(e);
                      } else {
                        handleProfileImageUpload(e);
                      }
                    }}
                    fullWidth
                    style={{ marginBottom: "10px" }}
                  />
                  <img
                    src={value + "?date=" + new Date().toString()}
                    alt={attribute}
                    style={{ height: "150px" }}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-grow flex-col">
                <p>{attribute}</p>
                  <input
                  key={attribute}
                  label={attribute}
                  value={value || ""}
                  onChange={handleChange}
                  name={attribute}
                  fullWidth
                  style={{ marginBottom: "10px" }}
                />
              </div>

              
            )
          )}

          {/* Add more input components for other fields */}
          <button type="submit">Submit</button>
        </form>
      </DialogPanel>
    </Dialog>
  );
}
