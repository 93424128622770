    
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './reusables/Footer';
import Header from './reusables/Header';
import { useEffect,useRef } from 'react';
import Plausible from 'plausible-tracker'
import Onboarding from './Pages/Recruiter/Onboarding/Onboarding.jsx';
import React from 'react';
import ReactGa, { set } from 'react-ga';
import Landing from './Pages/Landing/Landing';
import { useLocation } from 'react-router-dom';
import Recruiter_welcome from './Pages/Recruiter/Recruiter_welcome/Recruiter_welcome';
import EmailVerification from "./Pages/EmailVerification/EmailVerification.jsx";
import SelectService from './Pages/Customer/Select_service/SelectService.jsx';
import Recruiter_dashboard from './Pages/Recruiter/Recruiter_dashboard/Recruiter_dashboard.jsx';
import Privacy_policy from './Pages/TermsAndPrivacy/PrivacyPolicy.jsx';
import Terms_and_conditions from './Pages/TermsAndPrivacy/TermsAndConditions.jsx';
import AdminDashboard from './Pages/Admin/Admin_dashboard.jsx';
import {axiosConfig} from './api/axiosConfig';

ReactGa.initialize('AW-16548072061');



export const plausible = Plausible({
  domain: 'recruiterservice.net',
  hashMode: true,
  trackLocalhost: false,
  apiHost: 'https://api.recruiterservice.net',
})
plausible.enableAutoOutboundTracking()
plausible.enableAutoPageviews()

function App() {



    useEffect(() => {
      
      const handleScroll = () => {
        if (window.scrollY > 0) {
          plausible.trackEvent('scrolled', {props: {page: window.location.pathname}});
          window.removeEventListener('scroll', handleScroll);
        }
      };
      window.addEventListener('scroll', handleScroll);

    }, []);

    // The following 2 useEffects code is NOT dog shit(7/23/2024) well it is... 
    //the goal here is to look for dialogs and when user clicks back on mobile or something, it will close dialogs instead of yeeting them out of the site
    //Now this can be done indivisually with each dialog, but I aint doing all that and modifying my entire code base and future code bases and dont get me started on indexing.
    // the point is that users expects this behavoir at all times, therefore I will add it. this is more like doing it in jquery, but you take the best of all worlds
    // now does that mean that the customer sometimes have to click back button one more time? thats a fair tradeoff...
    useEffect(() => {
        let dialogs = document.getElementsByClassName("inset-0"); 
          if(dialogs.length == 0){
            const url = new URL(window.location.href);
            url.searchParams.delete('popups');
            window.history.replaceState(null, null, url);
        }

        window.addEventListener("popstate", (e) => {
          let dialogs = document.getElementsByClassName("inset-0");
          if (dialogs.length > 0) {
            console.log("trying to hid dialog", dialogs[dialogs.length - 1]);
            for (let ele of dialogs) {
              ele.className = "hidden";
            }
            const url = new URL(window.location.href);
            url.searchParams.delete("popups");
            return;
          }
        });
    });

    //this code adds an observer to see if any popups appear, onyl popups will have the class "inset-0" and if they do, a new history state will appear 
    // so that when users click back button, it will close all popup instead of going back to previous page
    useEffect(() => {
      // Callback function to execute when mutations are observed
      const callback = (mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            // Check if an element with the class name 'myClass' was added
            const addedNodes = Array.from(mutation.addedNodes);
            for (let node of addedNodes) {
              if (
                node.nodeType === Node.ELEMENT_NODE &&
                node.classList.contains("inset-0")
              ) {
                console.log('Element with class "inset-0" appeared:', node);
                let dialogs = document.getElementsByClassName("inset-0"); 
                const url = new URL(window.location.href);
                url.searchParams.set('popups', dialogs.length);
                if (window.location.href.includes("popups")) {
                  window.history.replaceState(null, null, url);
                } else {
                  window.history.pushState(null, null, url);
                }
              }
            }
          }
        }
      };

      // Create a MutationObserver instance
      const observer = new MutationObserver(callback);
      let parent = document.getElementById("headlessui-portal-root");
      // Start observing the document body for childList changes
      observer.observe(parent, {
        childList: true,
        subtree: true,
      });

      // Cleanup the observer on component unmount
      return () => {};
    }, []);

    return (
    <>
      
        <Router>
          <Header/>
          <Routes>
            <Route  path="/" element={<Landing/>}></Route>
            <Route  path="/email_verification" element={<EmailVerification/>}></Route>
            <Route  path="/recruiter_welcome" element={<Recruiter_welcome/>}></Route>
            <Route  path="/recruiter_dashboard" element={<Recruiter_dashboard/>}></Route>
            <Route  path="/onboarding" element={<Onboarding/>}></Route>
            {/* <Route  path="/customer_welcome" element={<Customer_welcome/>}></Route> */}
            <Route  path="/select_service" element={<SelectService/>}></Route>
            <Route  path="/privacy" element={<Privacy_policy/>}></Route>
            <Route  path="/terms" element={<Terms_and_conditions/>}></Route>
            <Route  path="/admin" element={<AdminDashboard/>}></Route>
          </Routes>
          <Footer/>
        </Router>
      
    </>
    
    
  );
}

export default App;
