
import React, { useState } from "react";
import { useEffect } from "react";
import {
  getAllRecruiters,
  uploadOnboardingData,
  uploadCompanyLogo,
  uploadProfileImage,
  getTier,
  approveRecruiter,
  blacklistRecruiter,
} from "../../api/recruiter";
import RecruiterCard from "../../reusables/RecruiterCard";
import { useDispatch, useSelector } from "react-redux";
import Edit_recruiter from "./EditRecruiter";
import { Dialog, DialogPanel } from "@headlessui/react";
import { DialogBackgroundStyle, smallDialogPanelStyle } from "../../constants";

export default function Approve_recruiter() {
  const [recruiters, setRecruiters] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRecruiter, setSelectedRecruiter] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [displayedRecruiters, setDisplayedRecruiters] = useState([]);

  const [openBlackList, setOpenBlackList] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    getAllRecruiters().then((res) => {
      setRecruiters(res.recruiters);
    });
    getTier().then((res) => {
      dispatch({ type: "UPDATE_TIER", payload: res.tiers });
    });
  }, []);

  const handleOpenEdit = (recruiter) => {
    console.log(recruiter);
    setSelectedRecruiter(recruiter);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const blacklistSelectedRecruiter = async () => {
    console.log("here", selectedRecruiter);
    let blacklisted = await blacklistRecruiter(selectedRecruiter.recruiter_id);
    if (blacklisted) {
      alert("Recruiter blacklisted");
      setRecruiters(
        recruiters.map((rec) => {
          if (rec.user_id === selectedRecruiter.recruiter_id) {
            return { ...rec, black_listed: true };
          }
          return rec;
        })
      );
      setOpenBlackList(false);
    } else {
      alert("Blacklist failed");
    }
  };

  useEffect(() => {
    if (showAll) {
      
      setDisplayedRecruiters(recruiters.filter(
        (recruiter) => recruiter.black_listed !== 1
      ));
    } else {
      setDisplayedRecruiters(
        recruiters.filter(
          (recruiter) =>
            recruiter.approved === 0 && recruiter.black_listed !== 1
        )
      );
    }
  }, [recruiters, showAll]);

  const handleOpenBlackList = (recruiter) => {
    setOpenBlackList(true);
    setSelectedRecruiter(recruiter);
  };

  const approve = async (recruiter) => {
    let approved = await approveRecruiter(recruiter.user_id, {
      approved: true,
    });
    if (approved) {
      alert("Recruiter approved");
      setRecruiters(
        recruiters.map((rec) => {
          if (rec.user_id === recruiter.user_id) {
            return { ...rec, approved: true };
          }
          return rec;
        })
      );
    } else {
      alert("Approval failed");
    }
  };

  return (
    <>
      <div className="flex flex-col gap-8 m-7">
        <button
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Unapproved" : "Show All"}
        </button>
        <div className="flex flex-wrap gap-5">
          {displayedRecruiters.map((recruiter) => {
            return (
              <div className="flex flex-col gap-3" key={recruiter.recruiter_id}>
                <RecruiterCard recruiter={recruiter} />
                <button
                  color="primary"
                  variant="contained"
                  onClick={() => approve(recruiter)}
                >
                  Approve
                </button>
                <button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleOpenEdit(recruiter)}
                >
                  edit
                </button>
                <button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleOpenBlackList(recruiter)}
                >
                  blacklist
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <Dialog open={openBlackList} onClose={() => setOpenBlackList(false)} className={DialogBackgroundStyle}>
        <DialogPanel className={smallDialogPanelStyle} >
          <p variant="h3">Blacklist Recruiter</p>
          <p variant="p">
            Are you sure you want to blacklist {selectedRecruiter?.name}?
          </p>
          <button
            variant="contained"
            color="primary"
            onClick={() => blacklistSelectedRecruiter()}
          >
            Yes
          </button>
          <button
            variant="contained"
            color="secondary"
            onClick={() => setOpenBlackList(false)}
          >
            No
          </button>
        </DialogPanel>
      </Dialog>

      <Edit_recruiter
        recruiter={selectedRecruiter}
        open={openEdit}
        handleClose={handleCloseEdit}
      />
    </>
  );
}
