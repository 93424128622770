import React,{useState,useEffect} from 'react'
import OrderCard from './order/OrderCard';
import { plausible } from '../App';
import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import CloseIcon from './helper_components/CloseIcon';
import LinkedIn_icon from '../assets/companyLogos/LinkedIn_icon.png';
import { useSelector } from 'react-redux';
import ShareIcon from './helper_components/ShareIcon';
import { DialogBackgroundStyle } from '../constants';
import SubmitOrder from './order/SubmitOrder';
const RecruiterCard = ({recruiter,preview=false}) => {
  const [open, setOpen] = useState(false);
  const tiers = useSelector((state) => state.tiers)
  const [order, setOrder] = useState(null);
  const [cost, setCost] = useState(null);
  const tier = tiers.find((tier) => tier.tier_name === recruiter.tier_name);

  const [openOrder, setOpenOrder] = useState(false);
    const user = useSelector((state) => state.user)

  const handleOpen = () => {
      setOpen(true);
  }
  const openOrderDialog = () => {
      setOpenOrder(true);
  }
  const closeOrderDialog = () => {
      setOpenOrder(false);
  }

  const handleClose = () => {
      setOpen(false);
  }
  const handleOrderSelection = (orderType,cost) => {
      plausible.trackEvent('Recruiter Card - Order Selected', {props: {recruiter:recruiter.name,recruiterID: recruiter.user_id, customerID:user?.id, orderType:orderType, cost:cost}})
      console.log(orderType,cost)

      if(orderType === 'One on One Meeting'){
          alert('One on One Meeting is not available yet. Please select another service')
          return
      }
      setOrder(orderType);
      setCost(cost);
      setOpenOrder(true);
  }


  const [timer, setTimer] = useState(null);
  useEffect(() => {
      if(open){
          plausible.trackEvent('Recruiter Card Viewed', {props: {recruiter:recruiter.name,recruiterID: recruiter.user_id, customerID:user.id, page: window.location.pathname}})
          setTimer(new Date().getTime())
      }
      if(!open && timer){
          let time = new Date().getTime() - timer;

          if(time > 60000){time = 60000} // hard caps at 1 minute
          setTimer(null)
          plausible.trackEvent('Recruiter Card Closed Time', {props: {recruiter:recruiter.name,recruiterID: recruiter.user_id, customerID:user.id, duration:time}})
      }
  }, [open])
// recruiter = {
//     "user_type": "recruiter",
//     "user_id": "_4XHSqCreE",
//     "is_test": 0,
//     "linkedIn_profile_link": "https://www.linkedin.com/in/cardercheryl",
//     "last_login_date": "2024-03-11T21:38:18.000Z",
//     "sign_up_method": "linkedin",
//     "recruiter_id": "_4XHSqCreE",
//     "service_quick_review": 1,
//     "service_revision": 1,
//     "service_meeting": 1,
//     "tier": "1",
//     "name": "Cheryl Cardersadsadsadsadsadsadd",
//     "company_list": "Sedron Technologies, Blue Origin, Redfin, BMW",
//     "company_logo": "https://recruiterservice.s3.amazonaws.com/companyLogo/_4XHSqCreE.jpeg",
//     "company_job_site": "https://www.sedron.com/careers/job-openings/,https://www.blueorigin.com/careers,https://careers.redfin.com/us/en,https://www.bmwgroup.jobs/us/en.html",
//     "resume": "https://recruiterservice.s3.amazonaws.com/recruiterResume/_4XHSqCreE.pdf",
//     "display_linkedIn": 1,
//     "short_description": "A results-driven Recruiter with 8+ years of experience in talent acquisition. Past regional recruiter for BMW, Caliber and Redfin, filling multiple roles over several locations. Contracted for Blue Origin for over 1 year, filling primarily avionic roles for the upcoming New Glenn rocket.  Currently working in waste management, hiring mechanical, electrical and chemical engineers. Happy to coach candidates to help them prepare for their interview, as the first impression is the most important!",
//     "hiring_in_these_industries": "Aerospace,Manufacturing,Engineering",
//     "most_frequently_hired_roles": "mechanical engineer, electrical engineer, manufacturing roles. ",
//     "search_keywords": null,
//     "years_of_experience": 8,
//     "profile_image": "https://recruiterservice.s3.amazonaws.com/profileImage/_4XHSqCreE.jpeg",
//     "average_rating": null,
//     "order_fullfillment_rate": null,
//     "average_response_time": null,
//     "active_orders": 0,
//     "number_of_orders": 0,
//     "is_technical": 0,
//     "job_title": "Sr Talent Acquisition Specialist ",
//     "activated": 1,
//     "profile_clicked": 0,
//     "approved": 1,
//     "black_listed": 0
// }
  return (
    <>
      <div
        onClick={handleOpen}
        className="group hover:bg-[#D3F4EF] transition-all duration-500 px-4 py-6 w-[327px] sm:w-[419px] h-[445px] sm:h-[494px] sm:px-6 sm:py-8  flex flex-col gap-6 bg-[#F9FBF9] rounded-[24px] cursor-pointer"
      >
        <div className="flex gap-4  sm:gap-6">
          <div className="relative flex-none rounded-full size-[80px] sm:size-[122px] overflow-hidden">
            <img
              src={recruiter.profile_image}
              className="w-[100px] sm:w-[152px] min-h-[100%]"
              alt={recruiter.name}
            />
          </div>

          <div className="overflow-hidden whitespace-nowrap">
            <p className="font-medium text-[20px] overflow-hidden whitespace-nowrap">
              {recruiter.name}
            </p>
            <p className="text-[14px] tracking-tight overflow-hidden whitespace-nowrap">
              {recruiter.job_title}
            </p>
            <p className="group-hover:bg-white-normal bg-[#D3F4EF] rounded-xl py-1 px-3 inline-block tracking-tight mt-4">
              Experience: {recruiter.years_of_experience} Years
            </p>
          </div>
        </div>

        <div className=" flex flex-wrap gap-1 h-12 overflow-hidden">
          {recruiter.company_list?.split(",").map((company, index) => {
            return (
              <p
                className="hover:text-branch text-[16px] hover:font-normal flex items-center gap-1 tracking-tight cursor-pointer"
                key={index}
                href={recruiter.company_job_site?.split(",")[index]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {company}
                <ShareIcon />
              </p>
            );
          })}
        </div>

        <div className="group-hover:bg-white-normal bg-[#E9FAF7] flex-grow rounded-[16px] p-2 sm:p-4 relative">
          <p>I have hired for these jobs:</p>
          <p>{recruiter.most_frequently_hired_roles}</p>
          {/* <a
            href={recruiter.linkedIn_profile_link}
            onClick={() => plausible.trackEvent("Recruiter Card - linkedin")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedIn_icon}
              alt="Linkedin"
              className="size-8 absolute bottom-2 left-2 cursor-pointer"
            />
          </a> */}
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        className={DialogBackgroundStyle}
      >
        <DialogPanel className="relative flex flex-col gap-4 overflow-y-auto max-h-[95dvh] sm:max-h[770px] max-w-[900px] bg-[#F9F8F9] rounded-[24px]  px-4 py-6 sm:px-6 sm:py-8">
          <div className="absolute top-3 left-3">
            <CloseIcon handleClose={handleClose} />
          </div>

          <a
            href={recruiter.linkedIn_profile_link}
            onClick={() => plausible.trackEvent("Recruiter Card - linkedin")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedIn_icon}
              alt="Linkedin"
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
              }}
              className="size-8 cursor-pointer md:size-10 lg:size-12"
            />
          </a>
          <div className="flex gap-4 sm:gap-6 items-center">
            <div className="relative flex-none rounded-full min-w-[80px] min-h-[80px] size-[20vw] sm:size-[122px] overflow-hidden">
              <img
                src={recruiter.profile_image}
                className=" w-[100%] sm:w-[152px]"
                alt={recruiter.name}
              />
            </div>

            <div className="overflow-hidden whitespace-nowrap">
              <p className="font-medium text-[20px] overflow-hidden whitespace-nowrap">
                {recruiter.name}
              </p>
              <p className="text-[14px] tracking-tight overflow-hidden whitespace-nowrap">
                {recruiter.job_title}
              </p>
              <p className=" bg-[#D3F4EF] rounded-xl py-1 px-3 inline-block tracking-tight mt-4">
                Experience: {recruiter.years_of_experience} Years
              </p>
            </div>
          </div>

          <div className="flex flex-wrap gap-1  my-4">
            {recruiter.company_list?.split(",").map((company, index) => {
              return !preview ? (
                <a
                  className="hover:text-branch text-[16px] hover:font-normal flex items-center gap-1 tracking-tight cursor-pointer"
                  key={index}
                  href={recruiter.company_job_site?.split(",")[index]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {company}
                  <ShareIcon />
                </a>
              ) : (
                <p
                  className="hover:text-branch text-[16px] hover:font-normal flex items-center gap-1 tracking-tight select-none"
                  key={index}
                >
                  {company}
                  <ShareIcon />
                </p>
              );
            })}
          </div>

          <div className="flex flex-col gap-4">
            <div className="ml-5 ">
              <p className="text-[#030304] font-semibold">Industries:</p>
              <p className="text-[#030304] ">
                {recruiter.hiring_in_these_industries}
              </p>
            </div>

            <div className="ml-5">
              <p className="font-semibold">About Recruiter</p>
              <p className="text-[#030304]">{recruiter.short_description}</p>
            </div>

            <div className="bg-[#E9FAF7] flex-grow rounded-[16px] p-2 sm:p-4 relative">
              <p className="text-[#030304] font-semibold">
                I had hired for these jobs:
              </p>
              <p className="text-[#030304]">
                {recruiter.most_frequently_hired_roles}
              </p>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row flex-wrap gap-4">
            <button
              className="flex-grow"
              disabled={recruiter.service_quick_review == "0"}
              onClick={() =>
                handleOrderSelection("Resume Review", tier?.price_quick_review)
              }
            >
              <p variant="h6">Resume Review</p>
              <p variant="p" fontSize="20px">
                ${tier?.price_quick_review}
              </p>
            </button>
            <button
              className="flex-grow"
              disabled={recruiter.service_revision === "0"}
              onClick={() =>
                handleOrderSelection("Resume Revision", tier?.price_revision)
              }
            >
              <p variant="h6">Resume Revision</p>
              <p variant="p" fontSize="20px">
                ${tier?.price_revision}
              </p>
            </button>
            <button
              className="flex-grow bg-branch/80"
              onClick={() =>
                handleOrderSelection("One on One Meeting", tier?.price_meeting)
              }
            >
              <p variant="h6" color="black">
                One-on-One Meeting
              </p>
              {/* <p variant='p'fontSize="20px" >${tier?.price_meeting}</p> */}
              <p variant="p" fontSize="20px" color="grey">
                comming soon
              </p>
            </button>
          </div>
          {openOrder ? (
            <SubmitOrder
              recruiter={recruiter}
              orderType={order}
              orderTotal={cost}
              open={openOrder}
              handleClose={closeOrderDialog}
            />
          ) : null}
        </DialogPanel>
      </Dialog>
    </>
  );
}

export default RecruiterCard
