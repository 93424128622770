import React from 'react'

const CheckIcon = () => {
  return (
    <svg
      className='!w-[24px] h-[33px] flex-none'
      xmlns="http://www.w3.org/2000/svg"
      width={'24px'}
      viewBox="0 0 24 33"
      fill="none"
    >
      <path
        d="M15 10.4578C14.053 10.1603 13.0452 10 12 10C6.47715 10 2 14.4771 2 20C2 25.5228 6.47715 30 12 30C17.5228 30 22 25.5228 22 20C22 18.9548 21.8396 17.947 21.5422 17"
        stroke="#048436"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.5 17.5L12 21L21.0002 11"
        stroke="#048436"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckIcon
