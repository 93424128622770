import React, { useEffect, useRef, useState } from 'react'
import RecruiterCard from '../../reusables/RecruiterCard';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import "swiper/css"
import {ArrowRightIcon,ArrowLeftIcon} from '@heroicons/react/24/solid'
import { Grid } from 'swiper';

const Recruiter_selection = ({recruiters}) => {
    
    const swiperRef = useRef();
    const determineSlides = () => {
            let width = window.innerWidth>1320? 1320 : window.innerWidth;
                    let slides = width/400 > 3.3 ? 3.3 : width/400;
                    let acutalSlides = slides < 1.3 ? 1.1 : slides;
                    acutalSlides = slides < 1 ? 1 : acutalSlides;
                    
                    return acutalSlides;
        }
    
    const [sidePerView,setSlidePerView] = useState(determineSlides());

    useEffect(() => {
        window.addEventListener('resize', () => {
                setSlidePerView(determineSlides());
        });
    },[])
    
    

    return (
      <Swiper
        spaceBetween={25}
        slidesPerView={sidePerView}
        slidesPerGroupAuto={true}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        
      >
        {recruiters?.map((recruiter) => (
          <SwiperSlide key={recruiter.user_id} className="my-4 w-[327px] sm:w-[420px]">
            <RecruiterCard recruiter={recruiter} />
          </SwiperSlide>
        ))}
        <div className="flex gap-8 justify-center mt-[20px] md:mt-[40px]">
          <button
            className="w-[64px] h-[64px] rounded-full"
            onClick={() => {
              swiperRef.current.slidePrev();
            }}
          >
            <ArrowLeftIcon className="size-6 selfCenter" />
          </button>
          <button
            className="w-[64px] h-[64px] rounded-full"
            onClick={() => {
              swiperRef.current.slideNext();
            }}
          >
            <ArrowRightIcon className="size-6 selfCenter" />
          </button>
        </div>
      </Swiper>
    );
}

export default Recruiter_selection
