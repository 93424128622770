import axios from "axios";  
import store from "../reducer/store";
import { baseBackendURL } from "../constants";
import { getToken } from "./recruiter";

export async function uploadOrder(type, formData) {
    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/uploadOrder?type=${type}`, formData, { headers: header });
        return response;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}
export async function getAllOrdersFromCustomer(customer_id) {
  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  };
  try {
    const response = await axios.get(
      `${baseBackendURL}/getAllOrdersFromCustomer?customer_id=${customer_id}`,
      { headers: header }
    );
    return response.data.orders;
  } catch (error) {
    console.log(error);
    
    return error.response.data.message;
  }
}
export async function markOrderAsViewedByCustomer(orderID,customerID) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/markOrderAsViewedByCustomer?orderID=${orderID}&customerID=${customerID}`, {}, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}

export async function submitOrderFeedback(orderID, feedback) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/submitOrderFeedback?orderID=${orderID}`, { feedback }, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}

export async function mark_order_as_paid(orderID,customerID) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/mark_order_as_paid?orderID=${orderID}&customerID=${customerID}`, {}, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}

export async function getOrders(recruiterID){
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.get(`${baseBackendURL}/getOrders?recruiter_id=${recruiterID}`, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}

export async function recruiterCompleteOrder(formData){
    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/recruiterCompleteOrder`, formData, { headers: header });
        return response;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}