import axios from "axios";  
import store from "../reducer/store";
import { baseBackendURL, isTestServer } from "../constants";


export async function uploadOnboardingData(data) {
    const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken()}`,
    };
    console.log("uploading onboarding data")
    console.log(data);
    let status = await axios
        .post(`${baseBackendURL}/uploadOnboardingData`, data,  { headers: header })
        .then((response) => {
            console.log(response);
            return response.status;
        })
        .catch((error) => {
            console.log(error)
        alert(error.response.data.message)
        return error.response;
        });
        return status
}


export async function blacklistRecruiter(recruiter_id) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/blacklistRecruiter?recruiter_id=${recruiter_id}`,{}, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}


export async function uploadProfileImage(formData) {
    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/uploadProfileImage`, formData, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}
export async function uploadCompanyLogo(formData) {
    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        console.log("uploading company logo")
        const response = await axios.post(`${baseBackendURL}/uploadCompanyLogo`, formData, { headers: header });
        console.log(response);
        return response.data.url;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}
export async function uploadRecruiterResume(formData) {
    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        console.log("uploading company logo")
        const response = await axios.post(`${baseBackendURL}/uploadRecruiterResume?recruiter_id=${formData.get('recruiter_id')}`, formData, { headers: header });
        console.log(response);
        return response.data.url;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}
export async function getAllRecruitersAndTierInfo(customerID) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.get(`${baseBackendURL}/getAllRecruitersAndTiers?customerID=${customerID}`, { headers: header });
        return response?.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }

}

export async function getAllRecruiters() {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.get(`${baseBackendURL}/getAllRecruiters`, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}

export async function approveRecruiter(recruiter_id) {
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/approveRecruiter?recruiter_id=${recruiter_id}`,{}, { headers: header });
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}

export async function getTier(){
    try {
        const response = await axios.get(`${baseBackendURL}/getTiers`);
        return response.data;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}


export function getToken() {
    const currentState = store.getState();
    const token = currentState.user.token;
    return token;
}