import React from "react";
import LinkedIn_icon from "../assets/companyLogos/LinkedIn_icon.png";

const Footer = () => {
  return (
    <div className="bg-[#09332B] flex justify-center items-center md:justify-between flex-wrap py-5 px-[10%] gap-7">
      <div className="text-[#E9E9EA] ">Copyright © 2024. RecruiterService</div>
      <div className="opacity-[0.5] text-white-normal flex gap-4">
        <a className="text-white-normal " target="_blank" href="/terms">
          Terms of Service
        </a>
        <a className="text-white-normal" target="_blank" href="/privacy">
          Privacy Policy
        </a>
      </div>
      <div className="mx-20 md:mx-0 border border-[#09332B] hover:border-white-normal p-2 rounded-full">
        <a
          href="https://www.linkedin.com/company/recruiterservice/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <svg
            fill="#ffffff"
            height="15px"
            width="15px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 260.366 260.366"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <path d="M34.703,0.183C15.582,0.183,0.014,15.748,0,34.884C0,54.02,15.568,69.588,34.703,69.588 c19.128,0,34.688-15.568,34.688-34.704C69.391,15.75,53.83,0.183,34.703,0.183z"></path>{" "}
                <path d="M60.748,83.531H8.654c-2.478,0-4.488,2.009-4.488,4.489v167.675c0,2.479,2.01,4.488,4.488,4.488h52.093 c2.479,0,4.489-2.01,4.489-4.488V88.02C65.237,85.539,63.227,83.531,60.748,83.531z"></path>{" "}
                <path d="M193.924,81.557c-19.064,0-35.817,5.805-46.04,15.271V88.02c0-2.48-2.01-4.489-4.489-4.489H93.424 c-2.479,0-4.489,2.009-4.489,4.489v167.675c0,2.479,2.01,4.488,4.489,4.488h52.044c2.479,0,4.489-2.01,4.489-4.488v-82.957 c0-23.802,4.378-38.555,26.227-38.555c21.526,0.026,23.137,15.846,23.137,39.977v81.535c0,2.479,2.01,4.488,4.49,4.488h52.068 c2.478,0,4.488-2.01,4.488-4.488v-91.977C260.366,125.465,252.814,81.557,193.924,81.557z"></path>{" "}
              </g>{" "}
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Footer;
