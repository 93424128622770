import React, { useState } from 'react';
import {sendVerificationCode,sendEmailVerified,loginOrSignUpWithEmailAndCode} from './emailVerification';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import { plausible } from '../App';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
const EmailVerificationAsLogin = ({hideText}) => {
    const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);

  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };  

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);


  const handleSendCode = async () => {

    

    //on IOS when they autofill email, it doesn't trigger the onChange event
    if(!email){
      const emailInput = document.getElementById('email');
      console.log(emailInput.value)
      setEmail(emailInput.value);
    }


    await sleep(500);
    if(email.includes("+")){
      alert("Sorry to prevent spam, we don't allow emails with + in them");
      return;
    } 

    plausible.trackEvent('Email Verification As Login - send code', {props: {email:email, page: window.location.pathname}})

    // Send verification code to the user's email
    setIsCodeSent(true);

    sendVerificationCode(email,'login')
  };

  const handleCodeChange = (e) => {
    let code = document.querySelector('#verificationCode').value || e.target.value;
    setVerificationCode(code);
  };
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleVerifyCode = async () => {

    
    let verified = await loginOrSignUpWithEmailAndCode(email,verificationCode)
    console.log(verified)
    if(verified.status===200){
        console.log(verified)
        let data = verified.data.user;
        let role = verified.data.user.user_type;
        let token = verified.data.token;
        
        console.log('verified')
        data['token'] = token;
        dispatch({ type: "LOGIN", payload: {...data,role:role,loginType:"email"} });
        setIsCodeVerified(true);
        
        if(window.location.pathname !== "/select_service"){
            setTimeout(() => {
                window.location.href = "/select_service";
            }, 1000);
        }
    }else{
        setIsCodeError(true);
    }
    plausible.trackEvent('Email Verification As Login - verify code', {props: {email:email,verified: verified.status===200, page: window.location.pathname}})
  };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection:'column', rowGap:'10px', border:hideText?'1':'px solid grey', borderRadius:'15px' }}>

                {!hideText &&
                  <>
                  <p variant="h5">It seems like you are not logged in!</p>
                  <p variant="p">Please enter your email so that we know who placed the order and that you can get updates when a recruiter completes it</p>
                  <p variant="p"> You can also use this email to view your order once the recruiter completes it</p>
                  </>
                }
                
                <input
                label="Email"
                id="email"
                placeholder='Email Address'
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                disabled={isCodeSent}
                />
                {isCodeSent ? (
                <>
                    <input
                    label="Verification Code"
                    variant="outlined"
                    value={verificationCode}
                    onChange={handleCodeChange}
                    id = "verificationCode"
                    placeholder='verification code'
                    />
                    <br />
                    {isCodeError ? <p style={{color:'red'}}>Code is incorrect</p> : null}

                    <button variant="contained" onClick={handleVerifyCode}>
                    {isCodeVerified ? <CheckCircleIcon /> : "Verify Code" } 
                    </button>
                    <p variant="p" style={{ textAlign: 'left',paddingTop:'15px' }}> We have sent a verification code to your email, if you didn't get it, please double check email/spam</p>
                </>
                ) : (
                <button variant="contained" onClick={handleSendCode} disabled={!(email.includes("@") && email.includes("."))}>
                    Send Verification Code
                </button>
                )}

                {!hideText &&
                <p variant="p" style={{ textAlign: 'left',paddingTop:'15px' }}> We will only use this email to notify you for update on orders</p>
                }
            </div>
        </div>
    );
};

export default EmailVerificationAsLogin;