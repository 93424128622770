import { Dialog, DialogPanel } from '@headlessui/react';
import React from 'react'
import { DialogBackgroundStyle, DialogPanelStyle, smallDialogPanelStyle } from '../constants';
import celebration from '../assets/celebration.gif';

const CelebrationPopup = ({open,handleClose,celebrationTitle,celebrationMessage,celebrationbuttonMessage}) => {
  return (
    <Dialog
      open={open}
      onClose={() => (window.location.href = "/select_service")}
      className={DialogBackgroundStyle}
      handleClose={handleClose}
    >
      <DialogPanel
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          rowGap: "15px",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={smallDialogPanelStyle}
      >
        <img src={celebration} alt="celebration" style={{ width: "300px" }} />
        <p variant="h3">{celebrationTitle}</p>
        <p variant="p">{celebrationMessage}</p>
        <button
          variant="contained"
          onClick={() => (window.location.href = "/select_service")}
        >
          {celebrationbuttonMessage}
        </button>
      </DialogPanel>
    </Dialog>
  );
}

export default CelebrationPopup
