import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ApproveRecruiter from "./ApproveRecruiter";
const AdminDashboard = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.recruiter?.user_id != "aAQ-Dgwg77") {
      window.location.href = "/";
    }
  }, []);
  return (
    <div>
      <h1>Admin dashboard Page</h1>
      <ApproveRecruiter />
    </div>
  );
};

export default AdminDashboard;
