import React, { useEffect, useState } from 'react'
import { recruiterCompleteOrder } from '../../api/order';
import CelebrationPopup from '../../reusables/CelebrationPopup';
import { Dialog, DialogPanel } from '@headlessui/react';
import { CancelButtonStyle, DialogBackgroundStyle, DialogPanelStyle } from '../../constants';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/solid';
import CloseIcon from '../helper_components/CloseIcon';
import DocViewer from "react-doc-viewer";
import axios from 'axios';

export default function CompleteOrder({open, handleClose,order,setOrderFullfilled,isCompletedOrder}) {
    const [revisedResumeB64, setRevisedResumeB64 ] = useState('');
    const [revisionCheckBox, setRevisionCheckBox] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [resumefile, setResumeFile] = useState(null);
    const [openCelebration, setOpenCelebration] = useState(false);
    const [resume, setResume] = useState(null);
    const [responseData, setResponseData] = useState({ 
        order_id: order.order_id,
        order_type: order.type,
        result_pdf_link : '',
        recruiter_comment : '',
        resume_pass_ats:'1'
    });


    // useEffect(() => {
    //     const fetchResume = async () => {
    //       if(!order.initial_resume_link) return;
    //       console.log(order)
    //       let response = await axios.get(order.initial_resume_link)
    //       // console.log(order)
    //       // let blob = await response.blob();
    //       //  const url = window.URL.createObjectURL(blob);
    //       //  console.log(url)
    //       // setResume(url);
    //     }
    //     fetchResume();     
    // },[order]);

    useEffect(() => {
        if(responseData.recruiter_comment===''){
            return setDisableSubmit(true);
        }
        if(order.type==="Resume Revision" && (revisedResumeB64==='' || !revisionCheckBox)){
            return setDisableSubmit(true);
        }
        if(order.type==="Resume Review" && (responseData.resume_pass_ats===undefined || responseData.resume_pass_recruiter==='' || responseData.ways_to_improve==='')){
            return setDisableSubmit(true);
        }
        setDisableSubmit(false);
    })



    const uploadRevisedResume = async (e) => {
            const file = e.target.files[0];
            if(!file || file.size > 10000000){
                alert("File size is too large, please upload a file less than 10MB");
                return;
            }
            setResumeFile(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64String = reader.result;
                
                setRevisedResumeB64(base64String);

            };
            
        }
    
    const handleSubmitOrder = async () => {
        console.log(responseData)
        const formData = new FormData();
        formData.append('orderData', JSON.stringify(responseData));
        formData.append('file', resumefile);
        const response = await recruiterCompleteOrder(formData);
        if(response.status===200){
            setOpenCelebration(true)
        }else{
            alert('There was an error, please contact Darren Zou on Linkedin')
        }
    }

    const handleCelebrationClose = () => {
        setOpenCelebration(false);
        setOrderFullfilled(order.order_id);
        handleClose();
        window.location.reload();
    }

    useEffect(() => {
        setResumeFile(null);
        setRevisedResumeB64('');
        setRevisionCheckBox(false);
        setResponseData({
            ...order,
            result_pdf_link : '',
            recruiter_comment : '',
            resume_pass_ats:'1'
        });
        if(isCompletedOrder){
            let recruiter_response_data = JSON.parse(order?.recruiter_response_data || "{}" )
            setResponseData({
                ...order,
                result_pdf_link : order.result_pdf_link,
                recruiter_comment : order.recruiter_comment,
                ...recruiter_response_data
            })
            setRevisedResumeB64(order.result_pdf_link)
        }
    }, [open])


    const disabledinputColor= {
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000"
        },
        "& .MuiInputLabel-root.Mui-disabled": {
            WebkitTextFillColor: "#000000"
          },
      }

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        className={DialogBackgroundStyle}
      >
        <DialogPanel
          style={{
            fontSize: "18px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
          className={DialogPanelStyle}
        >
          <CloseIcon handleClose={handleClose} />
          <h3>{order.type}</h3>
          {order.type === "Resume Review" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <p variant="p">
                Just review the resume and then give honest and direct feedback
                on what needs to be improved and what doesn't
              </p>
              <p variant="p">
                Please approach the review as if you are the recruiter looking
                to fill the position given
              </p>
              <p variant="p">Here are some guidelines: </p>
              <p variant="p">- Is the formatting good? </p>
              <p variant="p">
                - Should the customer rearrange/get rid of certain sections, ie:
                move work experience to near the top
              </p>
              <p variant="p">- Are there any grammar mistakes?</p>
              <p variant="p">
                {" "}
                <b>Note:</b> If no job posting was given, then just review the
                resume and give feedback.
              </p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              rowGap: "15px",
            }}
            className="w-full"
          >
            <p className="text-[25px] font-semibold">Customer Inputs</p>
            <div className="flex-col flex lg:flex-row gap-5">
              <div className="w-full flex-col flex flex-grow">
                <p variant="h6">Job Details</p>
                <input label="Job Title:" value={order.job_name} disabled />
              </div>

              <div className="w-full flex-col flex flex-grow">
                <p variant="h6">Company</p>
                <input label="Company:" value={order.company_name} disabled />
              </div>
            </div>

            <div className="w-full flex-col flex flex-grow">
              <p variant="h6">Job Requirements</p>
              <textarea
                label="Job Requirements"
                value={order.job_posting_description}
                rows={10}
                disabled
              />
            </div>

            <div className="w-full flex-col flex flex-grow">
              <p variant="h6">Customer's Resume</p>
              <iframe
                src={
                  "https://view.officeapps.live.com/op/embed.aspx?src=" +
                  order.initial_resume_link
                }
                frameborder="0"
                title="user's resume"
                className="h-[700px]"
              >
                .
              </iframe>
              <button
                variant="contained"
                onClick={() => window.open(order.initial_resume_link, "_blank")}
              >
                Download Resume
              </button>
            </div>

            <div className="w-full flex-col flex flex-grow">
              <p variant="h6">Relavent data the user submitted</p>
              <textarea
                label="User Submitted Data"
                value={order.user_submitted_data}
                rows={6}
                disabled
              />
            </div>

            <div className="w-full flex-col flex flex-grow">
              <p variant="h6"> User's Comments and Questions</p>
              <textarea
                rows={4}
                label="Comments"
                value={order.comments}
                disabled
              />
            </div>
          </div>
          <h2 className="border-t-2 pt-5">Complete Order</h2>
          {order.type === "Resume Revision" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <h3 variant="h6">Resume Revision</h3>
              <p variant="p">
                Since this is a Resume Revision, your job is to try to match the
                resume to the job description/fixing any other kind of grammar
                mistakes
              </p>
              <p variant="p">
                However you decide to revise the resume is entirely up to you,
                you can change anything and everything
              </p>
              <p variant="p">
                The purpose is to write a resume good enough to pass ATS and if
                you were the recruiter for this job posting,you will let the
                person to the next round
              </p>
              <p variant="p">Here are some guidelines: </p>
              <p variant="p">- Make sure the resume is in a readable format </p>
              <p variant="p">- Make sure the resume is ATS friendly</p>
              <p variant="p">
                - Make sure the resume is tailored to the job requirements
              </p>
              <p variant="p">- Make sure the resume is grammatically correct</p>
              <p variant="p">
                - Most importantly, it looks good to you, the recruiter!
              </p>
              <p variant="p">
                If the job posting is blank, then just touch up the resume and
                hand it back
              </p>
              <p variant="p">
                {" "}
                <b>Note:</b> If the customer simply does not have the experience
                required for the job, just revise this resume then explain the
                situation in the comments
              </p>
              <label
                variant="contained"
                component="label"
                style={{
                  width: "320px",
                  maxWidth: "100%",
                  height: "150px",
                  marginTop: "10px",
                  fontSize: "20px",
                  display: isCompletedOrder ? "none" : "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="cursor-pointer bg-branch hover:bg-branch-hover p-3 rounded-[20px] text-white-normal text-center"
                disabled={isCompletedOrder}
              >
                <ArrowUpOnSquareIcon style={{ fontSize: "50px" }} />
                Upload Revised Resume <br />
                (PDF only)
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={uploadRevisedResume}
                  hidden
                />
              </label>
              <p variant="p">
                If you can't find your revised resume, it is probably a word
                doc, please save/download as PDF in your editor or convert it to
                a pdf using this link:{" "}
                <a
                  href="https://cloudconvert.com/docx-to-pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://cloudconvert.com/docx-to-pdf
                </a>
              </p>
              {revisedResumeB64 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <iframe
                    src={`${revisedResumeB64}`}
                    title="revised resume"
                    width="100%"
                    height="1200px"
                  />
                  <p>
                    Please check the box below if you think this resume is good
                    enough
                  </p>

                  <div className="flex gap-3">
                    <input
                      type="checkbox"
                      name="goodenough"
                      id="goodenough"
                      onChange={(e) => {
                        setRevisionCheckBox(e.target.checked);
                      }}
                    />
                    <p>I think this resume is good enough</p>
                  </div>
                </div>
              )}
            </div>
          )}
          {order.type === "Resume Review" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <p variant="h6">Please answer the following questions</p>

              <form>
                <p id="ats-label">
                  Will this resume pass ATS for the job posting?
                </p>
                <select
                  id="ats-select"
                  value={responseData.resume_pass_ats}
                  defaultValue={1}
                  onChange={(e) =>
                    setResponseData({
                      ...responseData,
                      resume_pass_ats: e.target.value,
                    })
                  }
                  label="Will this resume pass ATS for the job posting?"
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </form>

              <form>
                <div>
                  <p>
                    Will you pass the resume to the next steps? Why or why not?
                  </p>
                  <textarea
                    id="ats-select"
                    value={responseData.resume_pass_recruiter}
                    label="Will you pass the resume to the next steps? Why or why not?"
                    className="w-full"
                    onChange={(e) =>
                      setResponseData({
                        ...responseData,
                        resume_pass_recruiter: e.target.value,
                      })
                    }
                    placeholder={`Yes, I would pass this resume to the next steps because...
                                
No, I would not pass this resume to the next steps because...`}
                    maxLength={1000}
                    rows={7}
                    required
                  />
                </div>
              </form>
              <div>
                <p>What are the ways to improve the resume?</p>
                <textarea
                  id="ats-select"
                  value={responseData.ways_to_improve}
                  onChange={(e) =>
                    setResponseData({
                      ...responseData,
                      ways_to_improve: e.target.value,
                    })
                  }
                  className="w-full"
                  label="What are the ways to improve the resume?"
                  placeholder={`Please provide actionable ways to improve the resume, such as 
changing the format to rearrange the sections such as putting X higher, shorten/expand projects section... etc, 
adding/removing experience/sections, 
reduce page number
change language to be more professional, for example ...
If you want this internship, definitely do some personal projects to show your interest in the field. 
                                `}
                  maxLength={1400}
                  rows={7}
                  required
                />
              </div>

              {/* <button
                        variant="contained"
                        component="label"  
                        style={{width:'350px',height:'150px',marginTop:'10px',fontSize:'20px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center'}}
                        >
                            <ArticleIcon style={{fontSize:'50px'}}/> 
                            Upload Revised Resume (PDF only)
                            <input type="file" accept="application/pdf" onChange={uploadRevisedResume}  hidden/>
                        </button> */}
              {/* {revisedResumeB64 && 
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <iframe src={`data:application/pdf;base64,${revisedResumeB64}`} title='revised resume' width="100%" height="1200px"/>
                            <p variant='h5' marginTop="20px">Please check the box below if you think this resume is good enough</p>
                            <FormControlLabel control={<Checkbox onChange={(e)=> setRevisionCheckBox(e.target.checked)}/>} label="I think this resume is good enough" />
                        </div>
                        } */}
            </div>
          )}
          <div>
            <p variant="p" style={{ marginTop: "20px" }}>
              Now enter any comment you might have for the customer, answering
              questions and give some guidance/advice
            </p>
            <textarea
              label="Any comments/advice/guidance"
              value={responseData.recruiter_comment}
              onChange={(e) =>
                setResponseData({
                  ...responseData,
                  recruiter_comment: e.target.value,
                })
              }
              placeholder={`Example: 
Hi (customer name),
Thank you so much for this order,
For your question about X, I recomend...
I recomend you to do more of Y in order to get the position you want. 
I think that Z position might also be a good fit for you, you can try applying there too.  
                                    `}
              rows={6}
              maxLength={2000}
              className="h-[200px] w-full"
            />
          </div>

          <button
            variant="contained"
            color="primary"
            style={{
              width: "200px",
              marginTop: "10px",
              display: isCompletedOrder ? "none" : "block",
            }}
            disabled={disableSubmit}
            onClick={handleSubmitOrder}
          >
            Complete Order
          </button>

          <button className={CancelButtonStyle} onClick={handleClose}>
            Cancel / Close
          </button>
          <CelebrationPopup
            open={openCelebration}
            handleClose={handleCelebrationClose}
            celebrationTitle="Order Completed!"
            celebrationMessage={`The order has been successfully completed, you have been paid $${order.recruiter_payout}! You can widthdraw under the Payment tab!`}
            celebrationbuttonMessage="$$$"
          />
        </DialogPanel>
      </Dialog>
    );
}
