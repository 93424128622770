import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './reducer/store';
import App from './App';
import {Helmet} from 'react-helmet';
import { GoogleOAuthProvider } from '@react-oauth/google';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <GoogleOAuthProvider clientId='636293265433-a1jrms4tma9ieimpik61csgegk5rgo2f.apps.googleusercontent.com'>
      <Provider store={store}>
        <Helmet>
          <title>RecruiterService</title>
          <meta name="description" content="RecruiterService connects you with currently employed recruiters for a really affordable price to review/revise resume and schedule one on one meetings" />
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
          {/* Add more meta tags as needed */}
        </Helmet>
        <App />
      </Provider>
  </GoogleOAuthProvider>
);
