import React from 'react'

const ShareIcon = () => {
  return (
    <svg
      className="flex-none"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      stroke="currentColor"
      fill='none'
    >
      <path
        d="M6.80012 3.33325C4.63737 3.33715 3.50484 3.39024 2.78107 4.11402C2 4.89508 2 6.1522 2 8.66641C2 11.1807 2 12.4378 2.78107 13.2188C3.56214 13.9999 4.81926 13.9999 7.33351 13.9999C9.8477 13.9999 11.1048 13.9999 11.8859 13.2188C12.6097 12.4951 12.6628 11.3625 12.6667 9.1998"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        
      />
      <path
        d="M13.2247 2.62379L8.09599 7.73089M13.2247 2.62379C13.1582 2.55157 13.0636 2.50658 12.949 2.49686C12.236 2.43661 10.6854 2.58244 10.6854 2.58244M13.2247 2.62379C13.2843 2.68856 13.3213 2.77526 13.33 2.87802C13.3901 3.59097 13.2444 5.14149 13.2444 5.14149"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShareIcon
