import axios from "axios";
import { baseBackendURL } from "../constants";
import { getToken } from "../api/recruiter";

export async function sendVerificationCode(email,intent='verify') {
    //intent can be "verify or login"
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/sendVerificationCode?email=${email}&intent=${intent}`, {}, { headers: header });
        return response;
    } catch (error) {
        console.log(error)
        alert(error.response.data.message)
        return error.response;
    }
}
export async function sendEmailVerified(email,userID,code){
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/markEmailVerified?email=${email}&userID=${userID}&code=${code}`, {}, { headers: header });
        return response;
    } catch (error) {
        alert(error.response.data.message)
        console.log(error)
        return error.response;
    }
}
export async function loginOrSignUpWithEmailAndCode(email,code){
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
    }
    try {
        const response = await axios.post(`${baseBackendURL}/loginOrSignUpWithEmailAndCode?email=${email}&code=${code}`, {}, { headers: header });
        return response;
    } catch (error) {
        alert(error.response.data.message)
        console.log(error)
        return error.response;
    }
}