import React, { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '../reusables/helper_components/CloseIcon';
import { GoogleLogin } from '@react-oauth/google';
import { LinkedInApi, baseBackendURL, baseRedirectURL } from '../constants';
import linkedinIcon from '../assets/companyLogos/LinkedIn_icon.png'; 
import { useSelector } from 'react-redux';
import user from '../reducer/user';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { plausible } from '../App';
import EmailVerificationAsLogin from '../reusables/Email_login';
import profile_placeholder from '../assets/profile_placeholder.png';
import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'

const AuthPopup = ({handleClose,open}) => {

    const user = useSelector((state) => state.user);
    

    const dispatch = useDispatch();
    
    useEffect(() => {
        if(open){
            plausible.trackEvent('AuthPopup', {props: {open:open, page: window.location.pathname}})
        }   
    }, [open])


    const googleLoginSuccess = async (response) => {
        plausible.trackEvent('AuthPopup - Google', {props: {page: window.location.pathname}})
        console.log(response);
        let decodedCredential = jwtDecode(response.credential);
        console.log(decodedCredential);
        let obj = {...decodedCredential}
        const header = {
            "Content-Type": "application/json",
        }
        console.log(obj)

        await axios.get(`${baseBackendURL}/loginOrSignUpWithGoogle?email=${obj.email}&name=${obj.name}&picture=${obj.picture}&sub=${obj.sub}` ,header)
            .then((res) => { 
                let userObj = res.data.user;
                let token = res.data.token;
                userObj["token"]= token;
                let role = userObj.user_type;
                dispatch({ type: "LOGIN", payload: {...userObj,profileImageURL:obj.picture,role,loginType:'google'} });
            })
            .catch((err) => plausible.trackEvent('Error', {props: {error:err, page: window.location.pathname}}));
        
        handleClose();
    };

    useEffect(() => {
        if(user.loggedIn){
         
            handleClose();
        }

    }, [user.loggedIn])


    const googleErrorMessage = (error) => {
        plausible.trackEvent('Error', {props: {error:error, page: window.location.pathname}})
        alert(error);
    };

    const userType = useSelector((state) => state.user.selected_role);

    const handleLinkedInLogin = () => {
        plausible.trackEvent('AuthPopup - LinkedIn', {props: {page: window.location.pathname}})
        const url = `${LinkedInApi.oauthUrl}&client_id=${LinkedInApi.clientId}&redirect_uri=${LinkedInApi.redirectUrl}&state=${userType}&scope=${LinkedInApi.scope}`;
        window.location.href = url;
    }
    return (
        <>
            <Dialog open={open} onClose={handleClose} className="z-50 w-full h-full top-0 left-0 bg-black-700/50 inset-0 fixed flex  items-center justify-center p-4" >
                <DialogPanel className="max-w-lg space-y-4 border bg-white p-6 bg-white-normal w-[348px] gap-6 rounded-xl">
                
                    <CloseIcon handleClose={handleClose}/>

                    <div>
                        <p variant="p" style={{fontSize:'18px', fontWeight:'700'}}>
                            Login/Signup as a {userType === "recruiter" ? "Recruiter" : "Customer"}
                        </p>
                        <p className=''>{userType == "customer" ? "Welcome back! Please enter your details" : "Please continue with Linkedin"}</p>
                    </div>

                    {userType == "customer" &&
                    <>
                        <EmailVerificationAsLogin hideText={true}/>
                        <p variant='p' style={{fontSize:"16px"}} onClick={()=> plausible.trackEvent("AuthPopup - continue as guest")}> <a href='/select_service' title='continue as guest'> Continue as guest</a></p>
                        <GoogleLogin onSuccess={googleLoginSuccess} onError={googleErrorMessage} width={"300px"}/>
                    </>
                    }

                    <button onClick={handleLinkedInLogin}
                        className='flex w-full p-[11px] bg-white-normal border border-grey-50 justify-center items-center rounded-md hover:bg-green-50 hover:border-black-300 text-black-900'
                    >
                        <img src={linkedinIcon} alt="Sign in with LinkedIn" style={{ maxHeight:'25px'}} /><p variant='p' fontSize={14} style={{width:'auto',flexGrow:'1'}}> Continue with LinkedIn</p> 
                    </button>

                    {userType === "customer" ?
                    <>
                        <p variant='p' style={{fontSize:"16px"}} onClick={()=> plausible.trackEvent("AuthPopup - continue as recruiter")}> <a href='/recruiter_welcome' title='continue as guest'> Click here if you are a recruiter</a></p>
                    </>
                    
                    :null}

                    {userType === "recruiter" ?
                        <>
                            <p className='text-[14px] '>
                            Note: <br></br>
                            Recruiters are <u>invite</u> only, the profile you create will only be approved if you are invited, visit Recruiter Service LLC on linkedin or email <u>support@recruiterservice.net</u> to join.
                            </p>
                            <p  style={{fontSize:"12px"}}>By signing in with Linkedin and creating an account, you agree to be an independant contractor and to our <a href='/privacy' target='_blank' >privacy policy</a> and <a href='/terms' target='_blank' >terms and condition</a></p>
                        </>
                        :<p variant="p" style={{fontSize:"12px"}}>By signing in with Linkedin and creating an account, you agree to our <a href='/privacy' target='_blank' >privacy policy</a> and <a href='/terms' target='_blank' >terms and conditions</a></p>

                    }

                    </DialogPanel>
            </Dialog>
        </>
    );
}

export default AuthPopup;
