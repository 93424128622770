import react, { useEffect } from "react";
import React, { useState } from "react";
import { uploadOrder } from "../../api/order.js";
import { useSelector } from "react-redux";
import moment from 'moment-timezone';
import CloseIcon from "../helper_components/CloseIcon.jsx";
import { plausible } from "../../App.js";
import { Dialog, DialogPanel } from "@headlessui/react";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/solid";
import EmailVerificationAsLogin from "../../Auth/EmailVerificationAsLogin.jsx";
import { DialogBackgroundStyle } from "../../constants.js";
export default function SubmitOrder({ recruiter, orderType, orderTotal,open, handleClose }) {

    const [resume, setResume] = useState(null);
    const [base64Resume, setBase64Resume] = useState("");
    
    const user = useSelector((state) => state.user);
    const [orderData, setOrderData] = useState({
        job_name: "",
        job_posting_description: "",
        initial_resume_link: "",
        comments: "",
        company_name: "",
        user_submitted_data: '',
        order:{
            orderType: orderType,
            orderTotal: orderTotal
        },
    });

    const [timezone, setTimezone] = useState(moment.tz.names());
    const [meetingData, setMeetingData] = useState({
            timeZone: "",
            duration: "",
            amount: "",
            avaliableTime: [],
            meetingTopic: "",
            meetingOrderTotal: ""
    });

    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if(open){
            setTimer(new Date().getTime());
            console.log("Order card opened")
            plausible.trackEvent('Order Card Opened', {props: {recruiter:recruiter.name,recruiterID: recruiter.user_id, customerID:user.id,orderType: orderType,cost:orderTotal,page: window.location.pathname}})
        }
    }
    , [open])
    
    const handleOrderClose = () => {
        if(timer){
            let timeSpent = (new Date().getTime() - timer);
            console.log({recruiter:recruiter.name,recruiterID: recruiter.user_id, customerID:user.id, timeSpent:timeSpent,orderType: orderType,cost:orderTotal,page: window.location.pathname})
            setTimer(null);
            plausible.trackEvent('Order Card Closed', {props: {recruiter:recruiter.name,recruiterID: recruiter.user_id, customerID:user.id, timeSpent:timeSpent,orderType: orderType,cost:orderTotal,page: window.location.pathname}})
        }
        handleClose();
    }

    const handleChange = (event) => {
        setOrderData({ ...orderData, [event.target.name]: event.target.value });
        console.log(orderData);
    };
    const handleMeetingChange = (event,value) => {
        setMeetingData({ ...meetingData, [event.target.name]: value });
    };
    const onFileChange = (event) => {
        const resume = event.target.files[0];
        console.log(resume)
        if(!resume.name.split('.').pop().includes('doc')){
            alert("Please upload a .doc or .docx file");
            return;
        }
        if(resume.size > 5242880){
            alert("File size too large. Resumes should not be more than 5MB");
            event.target.files[0] = null;
            return;
        }

        setResume(event.target.files[0]);
        if (resume) {
            let reader = new FileReader();
            reader.readAsDataURL(resume);
            reader.onloadend = () => {
                setOrderData({ ...orderData, initial_resume_link: reader.result });
            };
        }
    };


    const handleSubmit = async () => {
        if(orderData.resume === ""){
            alert("Please upload a resume");
            return;
        }
        if(!user.customer){
            alert("only customers can submit orders")
            return;
        }
        
        console.log("Submitting order", orderData);
        const formData = new FormData();
        formData.append("file", resume);
        delete orderData['initial_resume_link']
        formData.append('orderData', JSON.stringify(orderData));
        formData.append('customer_id', user.customer.user_id);
        formData.append('recruiter_id', recruiter.recruiter_id);
        
        let response = await uploadOrder(orderType, formData);
        console.log(response);
        if(response.status!=200){
            alert("Error uploading order", response.data.message);
            plausible.trackEvent('Error', {props: {error:response.data.message,errorType:"submitting order", page: window.location.pathname}});
            return;
        }
        plausible.trackEvent('Order Submitted', {props: {recruiter:recruiter.name,recruiterID: recruiter.user_id, customerID:user.id,orderType: orderType,cost:orderTotal,page: window.location.pathname}})
        if(response.data.url){
            setTimeout(() => {
            window.location.href = response.data.url;
            }, 1000);
        }    
        
        handleOrderClose(true);
        console.log("order uploaded sucessfully",);

        
    }

    const orderExplanationStyle= {display:'flex',flexDirection:'column', rowGap:'10px',marginBottom:'20px'}
    return (
      <Dialog
        open={open}
        onClose={handleOrderClose}
        className={DialogBackgroundStyle}
      >
        <DialogPanel className="flex flex-col p-7 h-[95vh] bg-white-normal rounded-lg gap-4 overflow-y-scroll">
          <CloseIcon handleClose={handleOrderClose} />
          <h2>Order type: {orderType}</h2>
          {orderType ? (
            orderType.includes("Review") ? (
              <div style={orderExplanationStyle}>
                <p >
                  Have this recruiter review your resume as if they were hiring
                  for that role.
                </p>
                <p >
                  With their years of experience, the recruiter can tell you
                  exactly whether you will move to next round or get your resume
                  thrown out.
                </p>
                <p >
                  You will get the following answers with this order:
                </p>
                <p >
                  - Will your resume pass the ATS(Application Tracking System)?
                </p>
                <p >
                  - Will the recruiter move you to the next step?
                </p>
                <p >- Ways to improve the resume.</p>
              </div>
            ) : orderType.includes("Revision") ? (
              <div style={orderExplanationStyle}>
                <p >Have the recruiter revise your resume</p>
                <p >
                  Recruiters are masters at revising resumes and they will give
                  you a hand crafted resume for that job you are going for.
                </p>
                <p >
                  Give your resume an edge over the other applicants and stands
                  out immediately!
                </p>
                <p >
                  If you just want the recruiter to revise your resume for any
                  job, you can just upload your resume
                </p>
                <p >
                  - Note: This is not a full resume writing service, you need to
                  have an resume to begin with.
                </p>
              </div>
            ) : orderType.includes("Meeting") ? (
              <div style={orderExplanationStyle}>
                <p >
                  A one on one meeting with a recruiter on Zoom.
                </p>
                <p >
                  You can ask anything such as exactly the step by steps you can
                  take to land your dream job
                </p>
                <p >Suggested meeting topic:</p>
                <p >- Mock Phone Interview</p>
                <p >
                  - Job consulting, asking the recruiter with your resume and
                  experience, what job do they recomend you apply to
                </p>
                <p >
                  - Literally ask them to give you a referal. This is how people
                  who attends career fairs have higher chance of getting hired!
                </p>
                <p >
                  Note: Recruiters do not operate on behalf of their company.
                  Some recruiter are not currently employed at the companies
                  listed on their profile
                </p>
              </div>
            ) : null
          ) : null}

          <div style={{ borderTop: "1px solid #e6e6e6" }}>
            <div className="flex flex-col ">
              <h3 variant="h5">Upload resume (Required)</h3>

              <label
                component="label"
                className="bg-branch rounded-3xl hover:bg-branch-hover cursor-pointer text-white-normal p-4 text-center my-5"
                style={{
                  width: "280px",
                  height: "100px",
                  marginTop: "10px",
                  fontSize: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ArrowUpOnSquareIcon
                  style={{ fontSize: "50px", display: resume ? "none" : "" }}
                  className="text-white-normal "
                />
                {resume ? "Resume selected: " + resume.name : "Upload Resume"}
                <input
                  type="file"
                  accept=".doc, .docx"
                  onChange={onFileChange}
                  hidden
                />
              </label>
              <p
                
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: resume ? "none" : "",
                }}
              >
                Please upload a word doc of your resume, if it is a pdf, please
                go back to your text editor and download as word doc{" "}
              </p>
              <h3 className="mb-6">Please answer the following questions</h3>
              <div className="flex gap-3 flex-col lg:flex-row">
                <div className="flex-grow">
                  <p>The Job Title You Want</p>
                  <input
                    label="Job Title(optional)"
                    name="job_name"
                    maxLength={100}
                    onChange={handleChange}
                    className="w-full"
                    placeholder="Example: Junior Software Engineer, Product Manager.. etc "
                  />
                </div>
                <div className="flex-grow">
                  <p>The Company's Name</p>
                  <input
                    label="Company's Name(optional)"
                    name="company_name"
                    maxLength={100}
                    className="w-full"
                    placeholder="The name of the company"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mt-5">
                <p>The Job Posting Description</p>
                <textarea
                  name="job_posting_description"
                  label="Qualification and requirements(optional)"
                  maxLength={2000}
                  rows={20}
                  className="w-full"
                  onChange={handleChange}
                  placeholder="Please copy and paste Qualification and Responsibility from the job site
                Example: 
                Qualifications
                •Minimum education and experience required: Bachelor's degree in Computer Engineering, Computer Science, Computer Information Systems, Information Technology, or related field of study plus 5 years of experience in the job offered or as Software Engineer, Technical Architect, IT Consultant, or related occupation
                •Skills Required: Requires experience in the following: Agile SDLC; Hybrid SDLC; Application Architecture Disciplines; Microservices; Java; Javascript; Python; MongoDB; Oracle or MySQL; Kafka; Redis; REST API and Web API; Cloud technologies, such as Azure or Amazon Web Services (AWS) ; System Integration Testing; and Unit Testing

                --------------------------------
                Responsibilities
                •Eliminate or automate remediation of recurring issues to improve overall operational stability of software applications and systems
                •Work on modernization agenda to move the system towards distributed micro-service architecture and Public/Private Cloud
                •Write secure & high-quality code in python and React/Java Script to build applications that would be used directly by senior traders
                •Work closely with Quant Researchers and Traders to document functional requirements
                •Execute creative software solutions, design, development, and technical troubleshooting with ability to think beyond routine or conventional approaches to build solutions or break down technical problems
                •Lead communities of practice across software engineering to drive awareness and use of new and leading-edge technologies
                •Understand the roles and responsibilities associated to Product and Agile Transformation"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <p>Any data you can provide for our recruiters?</p>
              <textarea
                label="Data(optional)"
                name="user_submitted_data"
                maxLength={600}
                rows={9}
                placeholder={`Any good data you can provide to the recruiter for your most relavent experience. (leave blank if you resume already have data)

This can be data such as: 
- I developed a CI/DI pipline that saved my team 3 hours of human processing time weekly. 
- I averaged $160,000 in sales per month 
- Implemented the system that allowed the company to handle over 120,000 concuurent users
- I was the employee of the month for 3 consecutive months

Any data makes your resume stronger and more for the recruiter to work with.
                                     `}
                style={{ marginTop: "10px" }}
                onChange={handleChange}
              />
              <p className="mt-[10px]">Comments and Questions(optional)</p>
              <textarea
                label="Comments and Questions(optional)"
                name="comments"
                maxLength={300}
                rows={5}
                placeholder="Do you have any comments/questions for the recruiter?"
                style={{ marginTop: "10px" }}
                onChange={handleChange}
              />
            </div>
          </div>
          {orderType.includes("Meeting") ? (
            <div
              style={{
                width: "350px",
                display: "flex",
                rowGap: "10px",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <p variant="h6">Meeting details</p>
              <input
                label="Purpose of Meeting"
                name="meetingTopic"
                maxLength={100}
                onChange={handleMeetingChange}
              />
              {/* <Autocomplete 
                        label="Your Time Zone" 
                        name="meetingDuration" 
                        options={['20','40','60']}
                        onChange={(e,value)=> setMeetingData({ ...meetingData, duration: value })}
                        renderInput={(params) => <input {...params} label="Meeting Duration in minutes" />}
                        
                    />
                    <Autocomplete 
                        label="Your Time Zone" 
                        name="timeZone" 
                        options={timezone}
                        onChange={(e,value)=> setMeetingData({ ...meetingData, timeZone: value })}
                        renderInput={(params) => <input {...params} name="timeZone" label="Your TimeZone" />}
                    />
                    < variant="contained" color="primary" style={{marginTop:'10px'}} onClick={() => console.log(meetingData)}>display</> */}
            </div>
          ) : null}

          <div
            style={{
              borderTop: "1px solid #e6e8e6",
              marginTop: "30px",
              padding: "12px 12px 12px 12px",
              flexDirection: "column",
            }}
          >
            <h3 variant="h4">Payment summary: </h3>
            <div className="flex flex-col xl:flex-row justify-between">
              <div>
                <p className="text-[16px] md:text-[24px] font-semibold">
                  Order type: {orderType}
                </p>
                <p >Payment will be processed through Stripe</p>
              </div>
              <p className="text-[16px] md:text-[24px] font-bold">
                Total: $
                {orderType.includes("Meeting")
                  ? meetingData.meetingOrderTotal
                  : orderTotal}
              </p>
            </div>

            {user.loggedIn ? null : (
              <div
                style={{
                  border: "1px solid grey",
                  borderRadius: "12px",
                  padding: "12px",
                  marginTop: "30px",
                  marginBottom: "30px",
                  boxShadow: "4px 4px #F1f1f1",
                }}
              >
                <EmailVerificationAsLogin />
              </div>
            )}
            <button
              disabled={orderData.initial_resume_link === ""}
              onClick={handleSubmit}
              className="w-full h-[44px]  lg:h-[56px]"
              style={{
                display: user.loggedIn ? "block" : "none",
                marginTop: "20px",
              }}
            >
              Submit Order
            </button>
          </div>
        </DialogPanel>
      </Dialog>
    );
}