import React from 'react'
import FAQItems from './FAQItems';
const FAQSection = () => {
  const faqData = [
    {
      question: "Are there any contracts?",
      answer:
        "No contracts need to be signed. You can request to leave at any time.",
    },
    {
      question: "How is money released to me?",
      answer:
        'Under your dashboard there is a "Payment" tab, it tracks how much you have earned and you can press widthdraw and we can do a bank transfer, email you a check, or Paypal/Venmo. For those that wants alternative methods such as giftcards, feel free to reach out to us on Linkedin or email us at: <u>support@recruiterservice.net</u>',
    },
    {
      question: "Can I alter how much I charge for my service?",
      answer:
        "The price of your service is dependent on the tiers you unlock which are based on the amount of orders you fulfill, it is around 10 orders per tier. In your “Payment” tab you can toggle your price based on the tiers available to you.",
    },
    {
      question: "What happens if I don't fulfill/reject an order",
      answer:
        "You can always accept/reject an order within 3 days. If you accept an order you must complete it. If you rejected too many orders or did not fulfill an order, your account will be suspended pending investigation.",
    },
    {
      question: "Will I be paid hourly or per service?",
      answer:
        "You will be paid on completion of service, this is a 1099 position, we will give you a 1099 form if you made over $1,000 dollars per year on the platform.",
    },
    {
      question: "Am I obligated to do anything for RecruiterService?",
      answer:
        "The only obligation you have is to fullfil the orders within the due date with acceptable quality. You do not have to do anything else. But we will really appreciate it if you can put this website in your LinkedIn Bio.",
    },
    {
      question: "Do I need to check in on RecruiterService every day?",
      answer:
        "Unless you get recieve an order notification in your email, you dont even have to log into RecruiterService, so there is literally no maintance required!",
    },
    
  ];

  return (
    <div className="flex flex-col w-full mx-auto my-2 p-4 gap-4">
      {faqData.map((item, index) => (
        <FAQItems key={index} {...item} />
      ))}
    </div>
  );
};

export default FAQSection
