import React from 'react'

export default function CloseIcon({handleClose}) {
    return (
        <div onClick={handleClose} size="small" style={{width:'25px'}} className='hover:text-branch cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
        </div>
    );
}
