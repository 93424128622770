import { Cancel } from 'axios';
export let  baseBackendURL = "https://api.recruiterservice.net";

export let baseRedirectURL = "https://recruiterservice.net";

export let isTestServer = false;
if(window.location.href.includes('localhost')){
    console.log("development mode");
     baseBackendURL = "http://localhost:1818";
     baseRedirectURL = "http://localhost:3000/";
     isTestServer = true;
    console.log({baseBackendURL,baseRedirectURL});
}

export const LinkedInApi = {
    clientId: '78qr78voktyp9w',
    redirectUrl: baseRedirectURL,
    oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    scope: 'r_basicprofile',
    state: '123456'
};

export const NodeServer = {
    baseURL: baseBackendURL,
    getUserCredentials: '/getLinkedInUserCredentials'
};

export const orderTypes = {
    resumeReview: "Resume Review",
    resumeRevision: "Resume Revision",
    oneOnOneMeeting: "Meeting",
};

export const DialogBackgroundStyle ="fixed z-50 w-full top-0 left-0 bg-black-700/50 inset-0 flex items-center justify-center px-4 py-6";

export const DialogPanelStyle =
  "flex flex-col p-4 md:p-7 bg-[#FFFFFF] rounded-lg gap-4 max-h-[95dvh] overflow-scroll overflow-x-hidden ";

export const smallDialogPanelStyle = "flex flex-col p-4 md:p-7 bg-[#FFFFFF] rounded-lg gap-4  overflow-x-hidden";

export const CancelButtonStyle =
  "bg-[#FFFFFF] hover:bg-green-100 text-black-900 text-bold py-2 border px-4 rounded-xl";