import React,{useState,useEffect} from 'react'
import landingpage from '../../assets/landingpage.jpg';
import sixSecond from '../../assets/sixSecond.png';
import download from '../../assets/companyLogos/download.png';
import { useDispatch, useSelector } from 'react-redux';
import {getAllRecruitersAndTierInfo} from '../../api/recruiter';
import Recruiter_card from '../../reusables/RecruiterCard';
import Recruiter_selection from './Recruiter_selection';
import { plausible } from '../../App';
import { History } from 'swiper';
import CheckIcon from './customIcon/CheckIcon';
import landingPage2 from '../../assets/landingPage2.png';
import landingpage3 from '../../assets/landingpage3.png';
import exampleResume from '../../assets/exampleResume.png';
import landingpage_service_revision from '../../assets/landingpage_service_revision.png';
import landingpage_service_review from '../../assets/landingpage_service_review.png';
import landingpage_service_meeting from "../../assets/landingpage_service_meeting.png";
import google_logo from '../../assets/companyLogos/google_logo.png';
import Uber_logo_2018 from '../../assets/companyLogos/Uber_logo_2018.png';
import atnt from '../../assets/companyLogos/atnt.png';
import amazon_logo from '../../assets/companyLogos/amazon_logo.png';
import Deloitte_logo from '../../assets/companyLogos/Deloitte_logo.png';
import addidas_logo from '../../assets/companyLogos/addidas_logo.png';
import Meta_logo from '../../assets/companyLogos/Meta_logo.png';
import long_logo_singleline from '../../assets/long_logo_singleline.png';
import mini_icon_3 from '../../assets/mini icon 3.png';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import AuthPopup from '../../Auth/Auth_popup';
import { navigateToCorrectPage } from '../../reusables/Header';

const Landing = () => {

  const [recruiters, setRecruiters] = useState([]);
  const [openAuthPopup, setOpenAuthPopup] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {

        // Fetch recruiters from the server
        getAllRecruitersAndTierInfo("").then((response) => {
            //console.log("recruiters gotten:",response);
            setRecruiters(response.recruiters);
            console.log(response.recruiters[0])
            dispatch({type: 'UPDATE_TIER', payload: response.tiers});
        });
  }, []);

  const handleCloseAuthPopup = () => {
    setOpenAuthPopup(false);
  };

  const handleOpenAuthPopup = (id) => {
    plausible.trackEvent("landing - "+ id, {
      props: { source: window.location.pathname },
    });
    if (user.loggedIn === true) {
      navigateToCorrectPage(user);
    }else{
      setOpenAuthPopup(true);
    }
    

  };

  const navigateToSelect_service = (id) => {
    plausible.trackEvent("View All Recruiters", {
      props: { id: id, source: window.location.pathname },
    });
    window.location.href = "/select_service";
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <AuthPopup open={openAuthPopup} handleClose={handleCloseAuthPopup} />
      <div
        className="w-full h-[75dvh] lg:h-[80dvh] "
        id="Home"
        style={{
          background:
            "linear-gradient(273deg, rgba(0, 0, 0, 0.00) 20.12%, #031411 100%), url(" +
            landingpage +
            ")",

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "70%",
          backgroundPositionY: "center",
          position: "relative",
        }}
      >
        <div className="bg-green-800/60 md:bg-green-300/15 bg-cover w-full h-full relative">
          <div className="inset-center-mobile min-w-[335px] top-[20%]  sm:left-[20%] flex flex-col ">
            <div className="flex flex-row items-center mb-4">
              <div className="w-[4vw] mr-2 h-[2px] bg-branch"></div>
              <h6 className="text-[#d0f9E0] text-[16px] md:text-[18px]">
                50+ RECRUITERS LISTED HERE
              </h6>
            </div>

            <h1 className="text-[36px] md:text-[70px] tracking-tight text-white-normal font-bold">
              Searching for a job
            </h1>
            <h1 className="text-[36px] md:text-[7-px] tracking-tight text-[#d0f9E0] mt-[15px] font-bold">
              and having trouble?
            </h1>
            <h3 className="text-[20px] md:text-[32px] text-white-normal font-medium">
              Have Recruiters improve your job application!
            </h3>
            <div className="mt-[56px] md:mt-[72px] flex flex-col md:flex-row gap-[26px] ">
              <button
                className="px-8 py-[15px] text-[18px] text-center"
                onClick={() => handleOpenAuthPopup("Get Started Now button")}
              >
                Get Started Now
              </button>
              <button className=" text-center  px-8 py-[15px]  border text-[18px] bg-[#e9e9ea1a] hover:bg-white-normal hover:text-black-800 group">
                <a
                  href="#HowItWorks"
                  className="text-white-normal group-hover:text-black-900 hover:font-normal"
                >
                  See how it works
                </a>
              </button>
            </div>
          </div>

          <div className=" hidden absolute bottom-8 right-8 rounded-[24px] w-[256px]  md:w-[459px] lg:flex p-3 md:p-6 bg-[#ffffff]">
            <img
              className="w-[51px] h-[51px] md:w-[96px] md:h-[96px] mr-4"
              src={sixSecond}
              alt=""
            />
            <div>
              <p className="text-[14px] md:text-[18px] mb-2 font-semibold">
                Did you know?
              </p>
              <p className="text-[12px] md:text-[18px]">
                Recruiters take an average <br />
                of <b>6 seconds</b> to scan a resume.
              </p>
            </div>
          </div>
        </div>
        <img
          src={exampleResume}
          className="absolute top-[5%] left-[-30%] sm:left-[5%]  opacity-30 h-[200px] rotate-[-20deg]"
          aria-hidden
          alt=""
        />
        <img
          src={exampleResume}
          className="absolute bottom-[0%] hidden sm:left-[5%] opacity-20 h-[250px] rotate-[20deg]"
          aria-hidden
          alt=""
        />
        <img
          src={exampleResume}
          className="absolute bottom-[50%] hidden sm:right-[-10%] opacity-30 h-[200px] rotate-[-20deg]"
          aria-hidden
          alt=""
        />
      </div>

      <div
        id="OurRecruiters"
        className="w-full flex justify-center px-[20px] mt-[90px] mb-[90px] "
      >
        <div className=" px-5px max-w-[1321px] flex flex-col">
          <div className="flex justify-between w-full gap-9 items-center">
            <div>
              <h1 className="mb-4 font-semibold tracking-[-1px]">
                Have real Recruiters help you land a job!
              </h1>
              <p className="text-[16px] sm:text-[18px]">
                Have Recruiters improve your job application!
              </p>
            </div>
            <button
              onClick={() =>
                navigateToSelect_service("View All Recruiters button 1")
              }
              className="hidden md:flex text-[16px]  max-h-[44px] w-[167px] leading-[30px]"
            >
              View All Recruiters
            </button>
          </div>

          <div className="w-[90vw] max-w-[1321px] ">
            <Recruiter_selection recruiters={recruiters} />
          </div>

          <div className="w-full flex justify-center md:hidden">
            <button
              onClick={() =>
                navigateToSelect_service("View All Recruiters button 2")
              }
              className="flex justify-center md:hidden rounded-lg text-[16px] py-[8px] px-12  max-h-[44px] leading-[30px]  mt-8"
            >
              View All Recruiters
            </button>
          </div>
        </div>
      </div>

      <div
        id="HowItWorks"
        className=" slideInFromLeft flex flex-col max-w-[1320px] justify-center mx-4 px-4 sm:px-10 lg:px-[116px] pb- pt-[56px] md:py-[90px] rounded-2xl md:rounded-[56px] bg-[#E9FAF7]"
      >
        <div className=" text-center">
          <h1 className="mb-4 font-semibold tracking-[-1px]">How it works</h1>
          <p className="text-[16px] sm:text-[18px]">
            Have Recruiters improve your job application!
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center py-[30px] ">
          <div className=" mx-[8px]  flex flex-col gap-[24px] max-w-[303px md:max-w-[531px] ">
            <div className="flex items-center gap-[16px] md:gap-[44px] border-b border-b-[#E9ECF2] pb-[24px] ">
              <div className="rounded-full p-[18px] bg-[#B5E4CA] w-[60px] h-[60px] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 9C6 5.68629 8.68629 3 12 3V3C15.3137 3 18 5.68629 18 9V15C18 18.3137 15.3137 21 12 21V21C8.68629 21 6 18.3137 6 15V9Z"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 7L12 11"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-[24px] tracking-tight">
                  Choose a recruiter
                </h3>
                <p>You can choose any recruiter from our list.</p>
              </div>
            </div>
            <div className="flex items-center gap-[16px] md:gap-[44px] border-b border-b-[#E9ECF2] pb-[24px]">
              <div className="rounded-full p-[18px] bg-[#FFBC99] w-[60px] h-[60px] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2 2V1C1.44772 1 1 1.44772 1 2H2ZM12.1953 9.52866L11.2842 9.1166C11.1127 9.49577 11.194 9.94151 11.4882 10.2358L12.1953 9.52866ZM14 11.3333L14.7071 12.0404C14.8946 11.8529 15 11.5985 15 11.3333C15 11.0681 14.8946 10.8138 14.7071 10.6262L14 11.3333ZM11.3333 14L10.6262 14.7071C10.8138 14.8946 11.0681 15 11.3333 15C11.5985 15 11.8529 14.8946 12.0404 14.7071L11.3333 14ZM9.52866 12.1953L10.2358 11.4882C9.94151 11.194 9.49577 11.1127 9.1166 11.2842L9.52866 12.1953ZM7.33333 1H2V3H7.33333V1ZM13.6667 7.33333C13.6667 3.83553 10.8311 1 7.33333 1V3C9.72657 3 11.6667 4.9401 11.6667 7.33333H13.6667ZM13.1065 9.94072C13.4667 9.14428 13.6667 8.26081 13.6667 7.33333H11.6667C11.6667 7.97102 11.5296 8.57399 11.2842 9.1166L13.1065 9.94072ZM11.4882 10.2358L13.2929 12.0404L14.7071 10.6262L12.9024 8.82155L11.4882 10.2358ZM13.2929 10.6262L10.6262 13.2929L12.0404 14.7071L14.7071 12.0404L13.2929 10.6262ZM12.0404 13.2929L10.2358 11.4882L8.82155 12.9024L10.6262 14.7071L12.0404 13.2929ZM7.33333 13.6667C8.26081 13.6667 9.14428 13.4667 9.94072 13.1065L9.1166 11.2842C8.57399 11.5296 7.97102 11.6667 7.33333 11.6667V13.6667ZM1 7.33333C1 10.8311 3.83553 13.6667 7.33333 13.6667V11.6667C4.9401 11.6667 3 9.72657 3 7.33333H1ZM1 2V7.33333H3V2H1Z"
                    fill="#030304"
                  />
                  <ellipse
                    cx="7.33366"
                    cy="7.33329"
                    rx="1.33333"
                    ry="1.33333"
                    transform="rotate(-180 7.33366 7.33329)"
                    fill="#030304"
                  />
                  <path
                    d="M2 2L7.33333 7.33333"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-[24px] tracking-tight">
                  Select a Service
                </h3>
                <p>
                  Copy and paste over job qualification, requirement,
                  expectations, upload resume.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-[16px] md:gap-[44px] border-b border-b-[#E9ECF2] pb-[24px] ">
              <div className="rounded-full p-[18px] bg-[#CABDFF] w-[60px] h-[60px] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.78711 11.2867L10.5004 14L11.6938 12.8067C11.7632 12.7374 11.8166 12.6538 11.85 12.5616C11.8834 12.4694 11.8961 12.371 11.8871 12.2733L11.4538 7.53333"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.24695 3.14L5.33362 4.08667M6.24695 11.64C6.30893 11.7025 6.38266 11.7521 6.4639 11.7859C6.54514 11.8198 6.63228 11.8372 6.72028 11.8372C6.80829 11.8372 6.89543 11.8198 6.97667 11.7859C7.05791 11.7521 7.13164 11.7025 7.19362 11.64L12.467 6.36667C13.4478 5.38503 13.9993 4.05438 14.0003 2.66667C14.0003 2.48986 13.93 2.32029 13.805 2.19526C13.68 2.07024 13.5104 2 13.3336 2C11.9459 2.00101 10.6153 2.55245 9.63362 3.53333L4.36028 8.80667C4.2978 8.86864 4.2482 8.94238 4.21436 9.02362C4.18051 9.10486 4.16309 9.19199 4.16309 9.28C4.16309 9.36801 4.18051 9.45515 4.21436 9.53638C4.2482 9.61762 4.2978 9.69136 4.36028 9.75333L6.24695 11.64ZM11.9136 10.6667L12.8603 9.72667L11.9136 10.6667Z"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 4.54661L3.72667 4.11328C3.62902 4.10429 3.5306 4.11697 3.43841 4.15038C3.34622 4.1838 3.26254 4.23714 3.19333 4.30661L2 5.49994L4.72667 8.22661"
                    stroke="#030304"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-[24px] tracking-tight">
                  Submit and Wait
                </h3>
                <p>
                  Submit and wait for the recruiter to do their magic within a
                  week!
                </p>
              </div>
            </div>
          </div>

          <div className="pt-[24px] md:pt-0 md:ml-12">
            <h2>Improve your job application!</h2>
            <p>
              Our recruiters are real recruiters who's entire job is to review
              resumes and fill positions.
            </p>

            <button
              onClick={() =>
                navigateToSelect_service("How it works section button")
              }
              className="mt-8 md:mt-[160px]"
            >
              See Our Recruiters
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-1 max-w-[1320px] lg:gap-[86px] items-center justify-center mx-4  py-[56px] md:py-[90px]  rounded-[56px] ">
        <img
          src={landingPage2}
          className="mirror rounded-[20px] md:rounded-[40px] sm:max-w-[50%] sm:min-w-[420px]"
          alt=""
        />
        <div className="flex flex-col gap-2  justify-center">
          <h2 className="mt-8 md:mt-0 text-[28px] mb-[20px] lg:mb-[56px] ">
            What are Recruiters
          </h2>
          <div className="flex items-center bg-[#F9FBF9] rounded-[20px] gap-2 p-2 w-full">
            <CheckIcon />
            <p>
              Recruiters are professionals who are hired by companies to fill
              open positions.
            </p>
          </div>
          <div className="flex items-center bg-[#F9FBF9] rounded-[20px] gap-2 p-2 w-full">
            <CheckIcon />
            <p>
              Recruiters are the ones who posts job on sites like Linkedin and
              Indeed.
            </p>
          </div>
          <div className="flex items-center bg-[#F9FBF9] rounded-[20px] gap-2 p-2 w-full">
            <CheckIcon />
            <p>
              Recrutiers are the one who review resumes and filter candidates
            </p>
          </div>
          <div className="flex items-center bg-[#F9FBF9] rounded-[20px] gap-2 p-2 w-full">
            <CheckIcon />
            <p>
              Recruiters decides who goes to the next rounds of interviews{" "}
              <br /> and whos resume to throw out.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-1 max-w-[1320px] lg:gap-[86px] items-center justify-center mx-4  py-[56px] md:py-[90px]  rounded-[56px] ">
        <img
          src={landingpage3}
          className="md:hidden rounded-[20px] md:rounded-[40px] sm:max-w-[50%] sm:min-w-[420px]"
          alt=""
        />

        <div className="flex flex-col gap-2  justify-center">
          <h2 className="mt-8 md:mt-0 text-[28px] mb-[20px] lg:mb-[56px] ">
            Our Recruiters can help you
          </h2>
          <div className="flex items-center bg-[#E9FAF7] rounded-[20px] gap-4 p-2 pr-5 w-full">
            <CheckIcon />
            <p>
              Polish your resume by changing the format, improve wording,
              highlighting important parts, etc
            </p>
          </div>
          <div className="flex items-center bg-[#E9FAF7] rounded-[20px] gap-2 p-2 pr-5 w-full">
            <CheckIcon />
            <p>
              Tell you exactly whether or not your resume will be passed up for
              this opportunity and how to improve
            </p>
          </div>
          <div className="flex items-center bg-[#E9FAF7] rounded-[20px] gap-2 p-2 pr-5 w-full">
            <CheckIcon />
            <p>
              Tell you what kind of job you might be considered for given your
              skill set.
            </p>
          </div>
        </div>
        <img
          src={landingpage3}
          className="hidden md:flex rounded-[20px] md:rounded-[40px] sm:max-w-[50%] sm:min-w-[420px]"
          alt=""
        />
      </div>

      <div className="relative overflow-hidden bg-[#3C8157] w-full py-[56px] px-4 md:py-[80px] md:px-[80px] lg:py-[100px] xl:px-[300px]">
        <h1 className="text-white-normal">
          Our Recruiters might even be the one to see your application if you
          apply to a company that they are currently working at.
        </h1>
        <img
          src={exampleResume}
          className="absolute top-[10%] lg:right-[5%] right-[10%] w-[180px] rotate-[-10.8deg] opacity-[30%]"
          alt=""
          aria-hidden
        />
      </div>

      <div
        id="Services"
        className="bg-black-900 flex justify-center w-full py-[56px] px-4  md:py-[80px] md:px-[80px] xl:px-[200px] lg:py-[100px] xxl:px-[300px]"
      >
        <div className="w-full max-w-[1321px]">
          <div>
            <h1 className="text-white-normal mb-3">Services</h1>
            <p className="text-[#E9E9EA] opacity-[0.6]">
              We offer very affordable services so you can work with multiple
              recruiters, increasing your chances!
            </p>
          </div>

          <div className="flex flex-wrap gap-[30px] mt-[56px] max-w-[1320px] justify-center">
            <div className="flex flex-col gap-[28px] rounded-[40px] hover:bg-[#E9FAF7] justify-between items-center bg-white-normal w-[370px] sm:w-[420px] p-5 sm:p-10 sm:py-[60px]">
              <img
                src={landingpage_service_review}
                width={"120px"}
                alt=""
                className="rounded-3xl"
              />
              <h3 className="text-[24px] text-[#030304] font-semibold">
                Resume Review
              </h3>
              <p className="text-[18px] text-[#4A4C56] font-medium text-center">
                Have a recruiter review your resume and give you feedback on
                what you can do better fit to the job description.
              </p>
              <button
                onClick={() =>
                  navigateToSelect_service("Service-Resume Review")
                }
              >
                Buy this service
              </button>
            </div>
            <div className="flex flex-col gap-[28px] rounded-[40px] items-center justify-between hover:bg-[#E9FAF7] bg-white-normal w-[370px] sm:w-[420px] p-5 sm:p-10 sm:py-[60px]">
              <img
                src={landingpage_service_revision}
                width={"120px"}
                alt=""
                className="rounded-3xl"
              />
              <h3 className="text-[24px] text-[#030304] font-semibold">
                Resume Revision
              </h3>
              <p className="text-[18px] text-[#4A4C56] font-medium text-center">
                Have your old resume rewritten, tailored specifically to your
                desired job.
              </p>
              <button
                onClick={() =>
                  navigateToSelect_service("Service-Resume Revision")
                }
              >
                Buy this service
              </button>
            </div>
            <div className="flex flex-col gap-[28px] hover:bg-[#E9FAF7] rounded-[40px] justify-between items-center bg-white-normal w-[370px] sm:w-[420px] p-5 sm:p-10 sm:py-[60px]">
              <img
                src={landingpage_service_meeting}
                width={"120px"}
                alt=""
                className="rounded-3xl"
              />
              <h3 className="text-[24px] text-[#030304] font-semibold">
                One-on-one Meeting
              </h3>
              <p className="text-[18px] text-[#4A4C56] font-medium text-center">
                Meet with a recruiter to discuss anything, from your resume, to
                career advise, to practicing interviews.
              </p>
              <button
                onClick={() =>
                  navigateToSelect_service("Service-Resume Meeting")
                }
              >
                Buy this service
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-5 bg-[#F9FBF9] py-[70px] p-6 w-full text-center">
        <h1>Our recruiters hires for the following Companies</h1>
        <p>
          And 100+ other companies that is growing every day as we gain more
          recruiters
        </p>
        <div className="flex gap-[57px] flex-wrap justify-center  mt-[40px]">
          <img src={google_logo} className="h-8" alt="" />
          <img src={Uber_logo_2018} className="h-8" alt="" />
          <img src={atnt} className="h-8" alt="" />
          <img src={amazon_logo} className="h-8" alt="" />
          <img src={Deloitte_logo} className="h-8" alt="" />
          <img src={addidas_logo} className="h-8" alt="" />
          <img src={Meta_logo} className="h-8" alt="" />
        </div>
      </div>

      <div className="w-full bg-[#3C8157] flex flex-col pl-4 py-[56px] md:py-[90px] md:px-[80px] xl:py-[120px] xl:px-[200px] relative overflow-hidden">
        <img
          src={long_logo_singleline}
          className="rounded-xl w-[220px] mb-6 cursor-pointer"
          alt=""
          draggable="false"
          onClick={() => document.getElementById("Home").scrollIntoView()}
        />
        <h1 className="text-white-normal max-w-[943px]">
          Let’s join Recruiter Service,
          <br /> Improve your job Application
        </h1>
        <div className="flex items-center mt-9 gap-3">
          <div className="rounded-full bg-white-normal/10 size-[45px] flex items-center justify-center">
            <EnvelopeIcon className="size-6 text-white-normal" />
          </div>
          <p className="text-white-normal text-[18px]">
            support@recruiterservice.net
          </p>
        </div>
        <img
          src={mini_icon_3}
          className="absolute select-none top-0 right-[-20%] lg:right-[-10%] xl:right-[0%] z-0"
          aria-hidden
          alt=""
          draggable="false"
        />
      </div>
    </div>
  );
}


export default Landing
