import React,{useState,useRef} from 'react'

const FAQItems = ({question,answer}) => {
  const [isOpen, setIsOpen] = useState(false);

  const contentRef = useRef(null);

  return (
    <div
      className="border-b border-gray-200 px-6 py-4 md:px-8 rounded-[16px] md:rounded-[24px] hover:bg-[#E9FAF7]"
      style={{
        cursor: "pointer",
        backgroundColor: isOpen ? "#E9FAF7" : "#F9FBF9",
      }}
    >
      <button
        className="flex justify-between w-full text-left bg-transparent px-0 items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-[18px] md:text-[24px] font-medium text-[#030304] tracking-tighter">
          {question}
        </p>

        <p className=" flex-none text-[25px] font-medium text-gray-900 text-center">
          {isOpen ? "-" : "+"}
        </p>
      </button>

      <div
        ref={contentRef}
        className="transition-all duration-300 ease-out overflow-hidden"
        style={{
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
        }}
      >
        <div className="mt-2 text-[#030304] text-[16px] md:text-[18px]"
          dangerouslySetInnerHTML={{ __html: answer }}
        >
          
        </div>
      </div>
    </div>
  );
};


export default FAQItems
