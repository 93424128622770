import React, { useEffect, useState } from "react";
import {
  sendVerificationCode,
  sendEmailVerified,
} from "../../Auth/emailVerification";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { plausible } from "../../App";
import { Input } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

function EmailVerification() {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleSendCode = async () => {
    //on IOS when they autofill email, it doesn't trigger the onChange event
    if (!email) {
      const emailInput = document.getElementById("email");
      setEmail(emailInput.value);
    }

    await sleep(500);

    if (email.includes("+")) {
      alert("Sorry to prevent spam, we don't allow emails with + in them");
    }

    plausible.trackEvent("Email Verification, code sent", {
      props: { email: email, userID: user.id, page: window.location.pathname },
    });

    let response = await sendVerificationCode(email);

    if (response.status !== 200) {
      return;
    }
    // Send verification code to the user's email
    setIsCodeSent(true);
  };

  const handleCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleVerifyCode = async () => {
    let response = await sendEmailVerified(email, user.id, verificationCode);

    if (response.status !== 200) {
      setIsCodeError(true);
      return;
    }

    dispatch({ type: "EMAIL_VERIFIED" });
    plausible.trackEvent("Email Verification, code verified", {
      props: { email: email, userID: user.id, page: window.location.pathname },
    });
    if (user.recruiter) {
      if (user.recruiter.name) {
        return (window.location.href = "/recruiter_dashboard");
      } else {
        window.location.href = "/onboarding";
      }
    } else if (user.customer) {
      window.location.href = "/select_service";
    }
  };

  useEffect(() => {
    if (user.email_verified) {
      if (user.recruiter) {
        if (user.recruiter.name) {
          return (window.location.href = "/recruiter_dashboard");
        }
        return (window.location.href = "/onboarding");
      } else if (user.customer) {
        return (window.location.href = "/select_service");
      }
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        rowGap: "20px",
      }}
    >
      <div className="flex flex-col items-center gap-4 md:gap-10 p-4">
        <h2>Email Verification</h2>
        <Input
          label="Email"
          id="email"
          value={email}
          className="border min-w-[250px] p-2 rounded-md"
          onChange={handleEmailChange}
          disabled={isCodeSent}
          placeholder="Enter your email address"
        />
        {isCodeSent ? (
          <>
            <Input
              label="Verification Code"
              variant="outlined"
              value={verificationCode}
              onChange={handleCodeChange}
              className="border min-w-[250px] p-2 rounded-md"
                placeholder="Enter verification code"
            />

            <button variant="contained" onClick={handleVerifyCode}>
              {isCodeVerified ? <CheckBadgeIcon /> : "Verify Code"}
            </button>
            <p style={{ textAlign: "left" }}>
              {" "}
              We have sent a verification code to your email, if you didn't get
              it, please double check email/spam
            </p>
            {isCodeError ? (
              <p style={{ color: "red" }}>Code is incorrect</p>
            ) : null}
          </>
        ) : (
          <button
            variant="contained"
            onClick={handleSendCode}
            disabled={!(email.includes("@") && email.includes("."))}
          >
            Send Verification Code
          </button>
        )}

        <p >
          We will only use this email to notify you for update on orders
        </p>
      </div>
    </div>
  );
}

export default EmailVerification;
