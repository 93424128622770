import React,{use, useEffect, useState} from 'react'
import { plausible } from '../../../App';
import { mark_order_as_paid } from '../../../api/order';
import {  getAllRecruitersAndTierInfo } from '../../../api/recruiter';
import { getAllOrdersFromCustomer } from "../../../api/order";
import { useDispatch, useSelector } from 'react-redux';
import OrderCard from '../../../reusables/order/OrderCard';
import celebration from '../../../assets/celebration.gif';
import { Dialog, DialogPanel } from '@headlessui/react';
import RecruiterCard from '../../../reusables/RecruiterCard'
import EmailVerificationAsLogin from '../../../Auth/EmailVerificationAsLogin';
import RecruiterRow1 from '../../../assets/RecruiterProfile/RecruiterRow1.png';
import RecruiterRow2 from '../../../assets/RecruiterProfile/RecruiterRow2.png';
import RecruiterRow3 from "../../../assets/RecruiterProfile/RecruiterRow3.png";
import landingpage from '../../../assets/landingpage.jpg';
import RecruiterBackground from '../../../assets/RecruiterProfile/RecruiterBackground.png';
import { DialogBackgroundStyle, DialogPanelStyle, smallDialogPanelStyle } from '../../../constants';

import { PageButton, Pagination, } from "react-headless-pagination";
import AdminDashboard from '../../Admin/Admin_dashboard';

import amazon_logo from '../../../assets/companyLogos/amazon_logo.png';
import Deloitte_logo from '../../../assets/companyLogos/Deloitte_logo.png';
import addidas_logo from '../../../assets/companyLogos/addidas_logo.png';
import Meta_logo from '../../../assets/companyLogos/Meta_logo.png';
import google_logo from '../../../assets/companyLogos/google_logo.png';
import Uber_logo_2018 from '../../../assets/companyLogos/Uber_logo_2018.png';
import atnt from '../../../assets/companyLogos/atnt.png';
import { ArrowLeftCircleIcon, ArrowLeftIcon, ArrowLongLeftIcon, ArrowRightCircleIcon, ArrowRightIcon } from '@heroicons/react/24/solid';


const SelectService = () => {
  const user = useSelector((state) => state.user);
  const tiers = useSelector((state) => state.tiers);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [recruiters, setRecruiters] = useState([]);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);
  const [openCongradulations, setOpenCongradulations] = useState(false);
  const [orders, setOrders] = useState([]);
  const [numCompletedUnviewedOrders, setCompletedOrderUnviewed] = useState(0);

  const [page, setPage] = useState(0);

  const [RecruiterCardPerPage, setRecruiterCardPerPage] = useState(9);
  const [maxPage, setMaxPage] = useState(0);

  const handlePageChange = (page) => {
      setPage(page);
      window.history.pushState(
        { page: page},
        "Page Change",
        '/select_service?page=' + page
      );
      plausible.trackEvent("Select Service, Recruiter Page Change", {
        props: { recruiter_page_number: page, width: window.innerWidth },
      });
  };
  const previousPage = () => {
    if(page>0){
      handlePageChange(page - 1);
    }else{
      handlePageChange(maxPage-1);
    }
  }
  const nextPage = () => {
    if(page<maxPage-1){
      handlePageChange(page+1);
    }else{
      handlePageChange(0);
    }
  }

  // sets pagnation and cards per page
  useEffect(() => {
    if(window.innerWidth<600){
      setRecruiterCardPerPage(6);
    }

    setMaxPage(Math.ceil(recruiters.length / RecruiterCardPerPage));
    window.addEventListener("popstate", (e) => {
      if (e.state) {
        setPage(e.state.page || 0);
      }
    });
  }, [recruiters]);


  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const [selectedView, setSelectedView] = useState("Select Recruiter");

  const handleSearch = () => {
    // Perform search logic here
    console.log("Search term:", searchTerm);
  };

  const mark_order_as_viewed = (orderID) => {
    setOrders(
      orders.map((order) => {
        if (order.order_id === orderID) {
          order.viewed_by_customer = 1;
          console.log("order", order);
        }

        return order;
      })
    );
    setTimeout(() => {
      setCompletedOrderUnviewed(
        (CompleteOrderUnviewed) => CompleteOrderUnviewed - 1
      );
    }, 1000);
  };
  const changeView = (view) => {
    if (view == selectedView) {
      return;
    }
    plausible.trackEvent("Select Service, Change View", {
      props: { view: view, page: window.location.pathname },
    });
    setSelectedView(view);
  };

  useEffect( () => {
    //console.log("fetching recruiters")
    let userID = user.user_id || "";
    // Fetch recruiters from the server
    if(recruiters.length === 0){
      getAllRecruitersAndTierInfo(userID).then((response) => {
      //console.log("recruiters gotten:",response);
      setRecruiters(response.recruiters);
      dispatch({ type: "UPDATE_TIER", payload: response.tiers });
    });
    }
    
    if (user.loggedIn) {
      fetchOrders();
    }
    
    
  }, [user.loggedIn]);


   const fetchOrders = async () =>{
    if (!user.loggedIn) {
      return;
    }
    let userID = user.user_id || "";
    const orders = await getAllOrdersFromCustomer(userID);
    
    let orderArr = [];
    orders["orders"]?.forEach((order) => {
      let orderItem = orders[order.type]?.filter(
        (item) => item.order_id === order.order_id
      );
      let combinedOrder = { ...order, ...orderItem[0] };
      if (combinedOrder.type === "Resume Review") {
        let responseData = JSON.parse(
          combinedOrder.recruiter_response_data
        );
        combinedOrder = { ...combinedOrder, ...responseData };
      }
      orderArr.push(combinedOrder);
    });

    orderArr.sort((a, b) => {
      return a.viewed_by_customer - b.viewed_by_customer;
    });

    setOrders(orderArr);

    //this is for the notification bubble to show how many orders are unviewed
    setCompletedOrderUnviewed(
      orderArr.filter(
        (order) =>
          order.order_status === "Completed" &&
          order.viewed_by_customer === 0
      ).length
    );
  }



  //checks if the order is successful
  useEffect(() => {
    const success = window.location.search.includes("success=true");
    if (success) {
      plausible.trackEvent("Order Payment Successful", {
        props: { page: window.location.pathname },
      });
      const orderID = window.location.search.split("orderID=")[1].split("&")[0];
      mark_order_as_paid(orderID, user.customer.customer_id).then(
        (response) => {
          console.log("Order marked as paid", response);
        }
      );
      setOpenCongradulations(true);
    }

    if (window.location.search.includes("canceled=true")) {
      alert("Order payment unsuccessful, it has been canceled");
      plausible.trackEvent("Order Payment Unsuccessful", {
        props: { page: window.location.pathname },
      });
      window.location.href = "/select_service";
    }
  }, []);

  function selectRecruiter(recruiter) {
    console.log("selected, ", recruiter);
    setSelectedRecruiter(recruiter);
  }
  
  return (
    <div className="flex flex-col items-center gap-4">
      <div
        className="flex w-full h-full justify-center gap-5 lg:gap-[60px] flex-col lg:flex-row items-center relative lg:bg-left"
        style={{
          backgroundImage: `url(${landingpage})`,
          backgroundPositionX: "20%",
          backgroundPositionY: "30%",
        }}
      >
        <div className=" absolute top-0 w-[100vw] h-full bg-gradient-to-r from-black-900 to-green-800 opacity-[80%]"></div>
        <div className="flex flex-col pt-[178px] pb-[122px] lg:p-10  justify-center max-w-[500px] overflow-hidden gap-6 mr-6 ">
          <h4 className="text-[#F9FBF9] opacity-[0.9] text-[36px] md:text-[48px] lg:text-[64px] leading-[1.3]">
            Our Recruiters
          </h4>
          <p className="text-[#F9FBF9] opacity-[0.8] ">
            Have Recruiters improve your job application!
          </p>
        </div>
        <div className="ml-[-10%] lg:ml-0 mb-10 lg:mb-0 min-w-[522px] max-w-[1015px] relative">
          <img className="opacity-0 w-[42%]" src={RecruiterRow1} alt="" />
          <img
            className="absolute left-[0%] top-0 w-[42%]"
            aria-hidden
            src={RecruiterRow1}
            alt=""
          />
          <img
            className="absolute left-[33%] w-[42%] top-0"
            src={RecruiterRow2}
            aria-hidden
            alt=""
          />
          <img
            className="absolute left-[66%] w-[42%] top-0"
            src={RecruiterRow3}
            alt=""
          />
        </div>
      </div>

      <div className="my-[50px] mx-4">
        <div>
          <div className="flex justify-center gap-3">
            <h2
              className={
                selectedView === "Select Recruiter"
                  ? "text-[24px] md:text-[32px] lg:text-[40px] tracking-tight hover:underline cursor-pointer font-bold"
                  : "text-[24px] md:text-[32px] lg:text-[40px] tracking-tight font-normal text-[#777980] hover:underline cursor-pointer"
              }
              onClick={() => changeView("Select Recruiter")}
            >
              Select Recruiter
            </h2>
            <div style={{ border: "1px solid grey" }}></div>
            <h2
              className={
                selectedView === "View Orders"
                  ? "text-[24px] md:text-[32px] lg:text-[40px] tracking-tight hover:underline cursor-pointer font-bold"
                  : "text-[24px] md:text-[32px] lg:text-[40px] tracking-tight font-normal text-[#777980] hover:underline cursor-pointer"
              }
              style={{
                width: "fit-content",
                fontWeight: selectedView === "View Orders" ? "700" : "500",
                borderRadius: "10px",
                border:
                  numCompletedUnviewedOrders > 0 ? "2px solid orange" : "",
              }}
              onClick={() => changeView("View Orders")}
            >
              View Orders
            </h2>
          </div>
          <p className="text-[#030304] opacity-[60] font-medium w-full text-center m-5">
            Have Recruiters improve your job application!{" "}
          </p>
          {selectedView === "Select Recruiter" && (
            <div className="flex flex-row max-w-[1321px] w-full flex-wrap gap-6 justify-center mt-10">
              <Pagination
                className="flex flex-col items-center w-full text-sm select-none"
                currentPage={page}
                edgePageCount={1}
                middlePagesSiblingCount={window.innerWidth>500? 1:0}
                setCurrentPage={handlePageChange}
                totalPages={maxPage}
                truncableClassName="px-3 w-[30px] lg:w-[60px] text-center"
                truncableText="..."
              >
                <div
                  className="flex flex-row my-[30px]"
                  style={{ display: recruiters.length > 0 ? "flex" : "none" }}
                >
                  <ArrowLeftIcon
                    onClick={() => previousPage()}
                    className="bg-white-normal hover:text-white-normal hover:bg-branch-hover h-[40px]  rounded-full c flex items-center justify-center size-[40px] lg:size-[60px] l p-2 lg:p-4  mx-2  text-[10px] lg:text-[16px]  text-black-800 border border-branch"
                  />
                  <div className="flex items-center justify-center flex-grow">
                    <PageButton
                      as=<button></button>
                      activeClassName="text-white-normal"
                      className="flex items-center justify-center size-[40px] lg:size-[60px] l p-4  px-5  mx-1 md:mx-3  rounded-full text-[16px] cursor-pointer text-black-800 border border-branch"
                      inactiveClassName="bg-white-normal"
                    />
                  </div>
                  <ArrowRightIcon
                    onClick={() => nextPage()}
                    className="bg-white-normal hover:text-white-normal hover:bg-branch-hover h-[40px]  rounded-full c  size-[40px] lg:size-[60px] l p-2 lg:p-4  mx-2  text-[10px] lg:text-[16px]  text-black-800 border border-branch"
                  />
                </div>
                <div className="flex gap-4 flex-col md:flex-row flex-wrap max-w-[100vw] items-center justify-center">
                  {recruiters.length > 0 ? (
                    recruiters
                      .slice(
                        page * RecruiterCardPerPage,
                        (page + 1) * RecruiterCardPerPage
                      )
                      .map((recruiter) => (
                        <RecruiterCard
                          recruiter={recruiter}
                          onClick={() => selectRecruiter(recruiter)}
                          tier={tiers[recruiter.tier]}
                          key={recruiter.user_id}
                        />
                      ))
                  ) : (
                    <h1>No recruiters found</h1>
                  )}
                </div>
                <div
                  className="flex flex-row mt-[30px]"
                  style={{ display: recruiters.length > 0 ? "flex" : "none" }}
                >
                  <ArrowLeftIcon
                    onClick={() => previousPage()}
                    className="bg-white-normal hover:text-white-normal hover:bg-branch-hover h-[40px]  rounded-full c flex items-center justify-center size-[40px] lg:size-[60px] l p-4  px-5 mx-3  text-[16px]  text-black-800 border border-branch"
                  />
                  <div className="flex items-center justify-center flex-grow">
                    <PageButton
                      as=<button></button>
                      activeClassName="text-white-normal"
                      className="flex items-center justify-center size-[40px] lg:size-[60px] l p-4  px-5 mx-3  rounded-full text-[16px] cursor-pointer text-black-800 border border-branch"
                      inactiveClassName="bg-white-normal"
                    />
                  </div>
                  <ArrowRightIcon
                    onClick={() => nextPage()}
                    className="bg-white-normal hover:text-white-normal hover:bg-branch-hover h-[40px]  rounded-full c flex items-center justify-center size-[40px] lg:size-[60px] l p-4  px-5 mx-3  text-[16px]  text-black-800 border border-branch"
                  />
                </div>
              </Pagination>
              <div className="flex flex-col items-center gap-5 bg-[#F9FBF9] py-[70px] min-w-[100vw] text-center mt-[100px]">
                <h2>Our recruiters hires for the following Companies</h2>
                <p>
                  And 100+ other companies that is growing every day as we gain
                  more recruiters
                </p>
                <div className="flex gap-[57px] flex-wrap justify-center mt-[40px]">
                  <img src={google_logo} className="h-8" alt="" />
                  <img src={Uber_logo_2018} className="h-8" alt="" />
                  <img src={atnt} className="h-8" alt="" />
                  <img src={amazon_logo} className="h-8" alt="" />
                  <img src={Deloitte_logo} className="h-8" alt="" />
                  <img src={addidas_logo} className="h-8" alt="" />
                  <img src={Meta_logo} className="h-8" alt="" />
                </div>
              </div>
            </div>
          )}

          {selectedView === "View Orders" && (
            <div className="mt-10 min-h-[400px]">
              {!user.loggedIn ? (
                <EmailVerificationAsLogin />
              ) : (
                <div className="flex flex-col items-center gap-10">
                  <div className="flex flex-col items-center gap-4">
                    <h3>Completed</h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "20px",
                      }}
                    >
                      {orders.length > 0 ? (
                        orders
                          .filter((order) => order.order_status === "Completed")
                          .map((order) => (
                            <OrderCard
                              order={order}
                              key={order.order_id}
                              markViewed={mark_order_as_viewed}
                              recruiters={recruiters}
                            />
                          ))
                      ) : (
                        <p variant="h5">
                          You have no orders completed currently!
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-4">
                    <h3 variant="h4">Pending</h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "20px",
                      }}
                    >
                      {orders.length > 0 ? (
                        orders
                          .filter((order) => order.order_status !== "Completed")
                          .map((order) => (
                            <OrderCard
                              order={order}
                              key={order.order_id}
                              markViewed={mark_order_as_viewed}
                              recruiters={recruiters}
                            />
                          ))
                      ) : (
                        <h5 variant="h5">
                          You have no orders pending currently!
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <Dialog
            open={openCongradulations}
            onClose={() => (window.location.href = "/select_service")}
            className={DialogBackgroundStyle}
          >
            <DialogPanel
              style={{
                margin: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "15px",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={smallDialogPanelStyle}
            >
              <img
                src={celebration}
                alt="celebration"
                style={{ width: "300px" }}
              />
              <p variant="h3">Order Placed!</p>
              <p variant="p">
                Your order has been placed, please wait a couple of days, we
                will update you through email when the recruiter fulfills your
                order.
              </p>
              <p variant="p">
                In the meantime, try placing orders for more recruiters! Don't
                put all of your eggs in one basket!
              </p>
              <button
                variant="contained"
                onClick={() => (window.location.href = "/select_service")}
              >
                Nice!
              </button>
            </DialogPanel>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default SelectService
