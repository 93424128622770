import { configureStore } from "@reduxjs/toolkit";

import user from "./user";
import tiers from "./tier";
const persistedState = JSON.parse(localStorage.getItem('reduxState')) || {};

export const store = configureStore({   
    reducer: {
        user: user,
        tiers: tiers,
    },
    preloadedState: persistedState

});
store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});
export default store;