import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";

import CompleteOrder from "../../../reusables/order/CompleteOrder";
import { getOrders } from '../../../api/order';
import { CancelButtonStyle, DialogBackgroundStyle, DialogPanelStyle, smallDialogPanelStyle } from "../../../constants";
import { Dialog, DialogPanel } from "@headlessui/react";

function Recruiter_dashboard() {
  const user = useSelector((state) => state.user);
  const [orderObj, setOrdersObj] = useState([]);
  const [openOrder, setOpenOrder] = useState(false);
  const [order, setOrder] = useState({});
  const [currentView, setCurrentView] = useState("Active Orders"); // ['active_orders', 'completed_orders', 'payments'
  const [displayOrders, setDisplayOrders] = useState([]); // [order1, order2, order3, ...

  const [openHelp, setOpenHelp] = useState(false);

  const closeHelp = () => {
    setOpenHelp(false);
  }

  const openHelpDialog = () => {
    setOpenHelp(true);
  }

  useEffect(() => {
    if (!user.loggedIn || !user.recruiter) {
      window.location.href = "/";
    }
  }, []);

  const handleClose = () => {
    setOpenOrder(false);
    setOrder({});
  };

  const changeView = (view) => {
    setCurrentView(view);
    switch (view) {
      case "Active Orders":
        setDisplayOrders(orderObj.filter((order) => order.fulfilled != 1));
        break;
      case "Completed Orders":
        setDisplayOrders(orderObj.filter((order) => order.fulfilled == 1));
        break;
      case "Payments":
        return;
      default:
        return;
    }
  };

  const handleOpenOrder = (selected_order) => {
    setOrder(selected_order);
    setOpenOrder(true);
  };

  const setOrderFullfilled = (order_id) => {
    setOrdersObj((prevOrders) => {
      return prevOrders.map((order) => {
        if (order.order_id === order_id) {
          return { ...order, fulfilled: 1 };
        }
        return order;
      });
    });
    setDisplayOrders((prevOrders) => {
      return prevOrders.filter((order) => order.order_id !== order_id);
    });
  };

  useEffect(() => {
    getOrders(user.recruiter.recruiter_id).then((data) => {
      console.log("orders", data.orders);
      let orders = data.orders;
      let orderArr = [];
      orders["orders"]?.forEach((order) => {
        let orderItem = orders[order.type]?.filter(
          (item) => item.order_id === order.order_id
        );
        let combinedOrder = { ...order, ...orderItem[0] };
        orderArr.push(combinedOrder);
      });
      console.log("orderArr", orderArr);

      setOrdersObj(orderArr);
      setDisplayOrders(orderArr.filter((order) => order.fulfilled != 1));
    });
  }, []);

  const getDueDate = (date) => {
    let dueDate = new Date(date);
    dueDate.setDate(dueDate.getDate() + 7);
    return dueDate.toDateString();
  };

  const round = (value) => {
    return Number(value).toFixed(2);
  };
  return (
    <div className="flex flex-col lg:flex-row  mb-10 items-center lg:items-start">
      <div className="w-[25%] min-w-[300px] max-w-[400px] lg:px-5 flex flex-col justify-between lg:pb-8 lg:h-[80vh] pb-5 lg:border-r-2">
        <div>
          <div className="flex items-center justify-center lg:h-[149px] flex-row-reverse">
            <p className="text-[25px] ">
              Welcome Back {user.recruiter.name.split(" "[0])}!
            </p>
          </div>
          <div className="flex flex-col gap-5 mt-[40px]">
            <button
              variant="contained"
              className={
                currentView == "Active Orders"
                  ? ""
                  : "bg-black-100 text-black-900 hover:bg-green-100"
              }
              onClick={() => changeView("Active Orders")}
            >
              Active Orders
            </button>
            <button
              variant="contained"
              className={
                currentView == "Completed Orders"
                  ? ""
                  : "bg-black-100 text-black-900 hover:bg-green-100"
              }
              onClick={() => changeView("Completed Orders")}
            >
              Completed Orders
            </button>
            <button
              variant="contained"
              className={
                currentView == "Payments"
                  ? ""
                  : "bg-black-100 text-black-900 hover:bg-green-100"
              }
              onClick={() => changeView("Payments")}
            >
              Payments
            </button>
          </div>
        </div>

        <button className="hidden lg:block" onClick={openHelpDialog}>Help</button>
      </div>

      <div className="w-full justify-center pr-4 lg:pr-10  pl-4 lg:pl-8">
        <div className="flex  lg:flex-row justify-between items-center mb-4 flex-col-reverse">
          <p className="text-[25px] pt-5 lg:pt-0">{currentView}</p>

          <div
            className="border p-2 rounded-xl w-full lg:w-[300px] flex flex-col items-center py-5 group hover:bg-green-100"
            onClick={() => changeView("Payments")}
          >
            <p className="text-[25px]">Lifetime Earnings</p>
            <p className="text-[#168118] text-[20px]">
              $
              {orderObj.length > 0
                ? round(
                    orderObj
                      .filter((order) => order.fulfilled == 1)
                      .reduce((sum, order) => sum + order.recruiter_payout, 0)
                  )
                : "0.00"}
            </p>
            <button className="">View Payments</button>
          </div>
          <div></div>
        </div>

        <div className="">
          {currentView == "Payments" ? (
            <div>
              <p variant="h4">
                Lifetime earned: $
                {orderObj.length > 0
                  ? round(
                      orderObj
                        .filter((order) => order.fulfilled == 1)
                        .reduce((sum, order) => sum + order.recruiter_payout, 0)
                    )
                  : "0.00"}
              </p>
              <p variant="h4">
                Total Orders Completed:{" "}
                {orderObj.length > 0
                  ? orderObj.filter((order) => order.fulfilled == 1).length
                  : 0}
              </p>
              <p variant="h4">The payment process is under development</p>
              <p variant="h6">
                Please email <u>darren@recruiterservice.net</u> or text 929-454-8448, he will
                transfer the money to you using your prefered method
              </p>

              <div className="p-5 m-5 border flex flex-col w-[300px] text-center rounded-3xl">
                <h3 variant="h4">Avalible for Widthdraw</h3>
                <h1 className="text-[#168118] mt-3 underline underline-offset-4">
                  $
                  {orderObj.length > 0
                    ? round(
                        orderObj
                          .filter(
                            (order) =>
                              order.fulfilled == 1 &&
                              order.paid_out_to_recruiter != 1
                          )
                          .reduce(
                            (sum, order) => sum + order.recruiter_payout,
                            0
                          )
                      )
                    : "0.00"}
                </h1>
              </div>
            </div>
          ) : (
            <div>
              {displayOrders.length == 0 && (
                <p variant="h4">
                  No active orders yet! We will send you an email when you
                  recieved an order
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
                className="justify-center lg:justify-start"
              >
                {orderObj &&
                  displayOrders.map((order) => {
                    return (
                      <div
                        key={order.order_id}
                        style={{
                          border: "3px solid black",
                          borderRadius: "10px",
                          width: "320px",
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                          gap: "10px",
                        }}
                        onClick={() => handleOpenOrder(order)}
                      >
                        <p variant="h4">
                          <b>{order.type}</b>
                        </p>
                        <p
                          variant="p"
                          style={{
                            color: "#168118",
                            fontWeight: "800",
                            fontSize: "25px",
                          }}
                        >
                          ${Number(order.recruiter_payout).toFixed(2)}
                        </p>
                        <p variant="p">
                          Due: {getDueDate(order.creation_date)}
                        </p>
                        <p variant="p">{order.company_name}</p>
                        <p variant="p">{order.job_name}</p>
                        <button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenOrder(order)}
                        >
                          View
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      <CompleteOrder
        open={openOrder}
        handleClose={handleClose}
        order={order}
        setOrderFullfilled={setOrderFullfilled}
        isCompletedOrder={currentView == "Completed Orders"}
      />

      <Dialog open= {openHelp} onClose={closeHelp}className={DialogBackgroundStyle}>
        <DialogPanel className={smallDialogPanelStyle}>
            <h3>If you need help with anything, please email <u>darren@recruiterservice.net</u></h3>
        </DialogPanel>
      </Dialog>
    </div>
  );
}
export default Recruiter_dashboard;
